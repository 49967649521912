import React, { useEffect, useState } from "react";
import {
  Portlet,
  PortletBody,
  PortletFooter,
  PortletHeader
} from "../../../components/Portlet";
import {connect, useSelector} from "react-redux";
import { CreateItem, GetItem, UpdateItem } from "../../../redux/actions/base";
import {
  AirportListRoute,
  CityListRoute,
  CountryListRoute, UserEditRoute,
  UserListRoute
} from "../../routesConstants";
import * as yup from "yup";
import FormRow from "../../base/components/forms/formRow";
import { Col, Form, Row } from "react-bootstrap";
import CitySelect from "../../base/components/forms/citySelect";
import LoadingButton from "../../base/components/loadingButton";
import { Formik } from "formik";
import ChangePassword from "./components/ChangePassword";
import GenerateLoginToken from "./components/GenerateLoginToken";
import { useParams } from "react-router-dom";
import _, {values} from 'lodash'
import {Helmet} from "react-helmet";
import BaseUserForm from "./components/baseUserForm";


const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required()
    .default(""),
  lastName: yup
    .string()
    .required()
    .default(""),
  operatorCode: yup
    .string().default(""),
  phone: yup.string().default(""),
  telegramUsername: yup.string().default(""),
  railwaysLogin: yup.string().default(""),
  ratehawkLogin: yup.string().default(""),
  email: yup
    .string()
    .email()
    .required()
    .default(""),
  active: yup.boolean().default(true),
  dontSendEmail: yup.boolean().default(false),
  role: yup
    .string()
    .required()
    .default("employee"),
  sendTgNotifications: yup
    .array()
    .of(
      yup.lazy(value =>
        typeof value === "number" ? yup.number() : yup.string()
      )
    ).default([]),
  specialPermissions: yup
    .array()
    .of(
      yup.lazy(value =>
        typeof value === "number" ? yup.number() : yup.string()
      )
    ).default([])
});

const UserEditPage = props => {
  const [user, setUser] = useState(null);
  const [loader, setLoader] = useState(false);
  const { roles } = useSelector(state => state.auth.user);


  const { id } = useParams();
  useEffect(() => {
    if (user === null) {
      setLoader(true);
      props.GetItem("/api/users", id, res => {
        setUser(res.data.user);
        console.log(res);
        setLoader(false);
      });
    }
  }, [user, id, props]);
  const callback = (values, cb) => {
    props.UpdateItem("/api/users/" + id, values, cb, roles.indexOf("admin") !== -1 ? UserListRoute : UserEditRoute+id);
  };
  return (
    <>
      <Helmet>
        <title>{user ? `${user.firstName} ${user.lastName}` : 'Пользователь'}</title>
      </Helmet>
      <div className={"row"}>
        <div className="col-xl-6">
          <Portlet>
            <PortletHeader title={"Редактирование пользователя"} />
            <BaseUserForm user={user} edit callback={callback}/>
          </Portlet>
        </div>
        <div className="col-xl-6">
          <ChangePassword userId={id} url={"/api/users/changePassword"} />
          {/*<GenerateLoginToken userId={id} url={"/api/users/generateToursLoginToken"}/>*/}
        </div>
      </div>
    </>
  );
};

export default connect(null, { GetItem, UpdateItem })(UserEditPage);


