import React, {useState} from 'react';
import numberFormat from "../../../helpers/numberFormat";
import {Link} from "react-router-dom";
import _ from 'lodash'

const renderMin = (number) =>{
  const standart = {textAlign:'end'}
  return number < 0 ? {color:'red',...standart}:{...standart}
}
const BuyerRowWithTable = ({buyer, start, end}) => {
  const [show, setShow] = useState(false)
  const debetSupp = _.sumBy(buyer.tableData, (r)=>r.netto + r.krs + r.krsKayar)
  return (
    <>
      <tr>
        <td colSpan={2} onClick={()=>setShow(!show)} className={'pointer'}>
          <div className={'d-flex justify-content-between align-items-center'}>
            <div>{buyer.counterparty.name}</div>
            <i className={`fa fa-chevron-${show ? 'up' : 'down'}`}/>
          </div>
        </td>
        <td style={renderMin(buyer.commentProd)}>{numberFormat(buyer.commentProd)}</td>
        <td style={renderMin(buyer.startDeb)}>{numberFormat(buyer.startDeb)}</td>
        <td style={renderMin(buyer.startCr)}>{numberFormat(buyer.startCr)}</td>
        <td style={renderMin(debetSupp)}>{numberFormat(debetSupp)}</td>
        <td style={{...renderMin(buyer.flowDeb), color:'grey'}}>{numberFormat(buyer.flowDeb)}</td>
        <td style={renderMin(buyer.flowCr)}>{numberFormat(buyer.flowCr)}</td>
        <td style={renderMin(buyer.endDeb)}>
          {/*<Link to={{*/}
          {/*  pathname: "/salesRegister/create",*/}
          {/*  search: `?start=${start}&end=${end}&id=${buyer.counterparty.id}`*/}
          {/*}}*/}
          {/*      target={'_blank'}>*/}
            {numberFormat(buyer.endDeb)}
          {/*</Link>*/}

        </td>
      </tr>
      {show && <>
        <tr style={{backgroundColor: 'rgba(100,108,154,0.18)'}}>
          <th>Выписан</th>
          <th>Билет</th>
          <th colSpan={2}>Пассажир</th>
          <th>Маршрут</th>
          <th>Нетто</th>
          <th>Крс 1с</th>
          <th>КрсКаяр 1с</th>
          <th>Итого</th>
        </tr >
        {buyer.tableData.map((r,i)=> {
          return <tr key={i}  style={{backgroundColor: 'rgba(100,108,154,0.05)'}}>
            <td>{r.issueDate}</td>
            <td>{r.ticket}</td>
            <td colSpan={2}>{r.pax}</td>
            <td>{r.route}</td>
            <td style={{textAlign:'end'}}>{numberFormat(r.netto)}</td>
            <td style={{textAlign:'end'}}>{numberFormat(r.krs)}</td>
            <td style={{textAlign:'end'}}>{numberFormat(r.krsKayar)}</td>
            <td style={{textAlign:'end'}}>{numberFormat(r.netto + r.krs + r.krsKayar)}</td>
          </tr>
        })}
      </>}
    </>
  );
};

export default BuyerRowWithTable;
