import React, {useEffect, useState} from 'react';
import {Portlet, PortletBody, PortletHeader} from "../../../components/Portlet";
import CustomDatePicker from "../../base/components/forms/customDatePicker";
import LoadingButton from "../../base/components/loadingButton";
import {Form, Tab, Table, Tabs} from "react-bootstrap";
import {useForm} from "react-hook-form";
import moment from "moment/moment";
import axios from "../../../helpers/axios";
import BlockUi from "@availity/block-ui";
import _ from "lodash";
import {Link} from "react-router-dom";
import {TouristServicesCreateRoute, TouristServicesEditRoute} from "../../routesConstants";
import numberFormat from "../../../helpers/numberFormat";
import Button from "react-bootstrap/Button";
import {tabsName} from "../../counterparty/components/sinkRateHawk";

const SinkIWay = ({active}) => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [addToInvoise, setAddToInvoise] = useState([])
  const [autoLoad,setAutoLoad]= useState(false)
  const start= moment().subtract(7,'d').format('DD.MM.YYYY')
  const end = moment().format('DD.MM.YYYY')
  const formController = useForm({
    start,end
  })
  const takeTable = (data) =>{
    setLoading(true)
    axios.post('/api/touristServices/iWay/revise',data)
      .then(({data})=>{
        console.log('data', data)
        setData(data.data)
      })
      .finally(()=>setLoading(false))
  }
  const createTouristServ = () =>{
    localStorage.setItem("items",  JSON.stringify(addToInvoise))
    localStorage.setItem('type', 'iway')
    window.open(TouristServicesCreateRoute,'_blank')
  }
  const onFocus = () => {
    console.log('focus')
    takeTable(formController.getValues());
  };

  useEffect(() => {
    if(active && autoLoad){
      window.addEventListener("focus", onFocus);
      // Calls onFocus when the window first loads
      console.log('first')
      // Specify how to clean up after this effect:
      return () => {
        window.removeEventListener("focus", onFocus);
      };
    }

  }, [active,autoLoad]);

  return (
    <Portlet>
      <PortletBody>
        <div className={'d-flex justify-content-between align-items-center'}>
          <form onSubmit={formController.handleSubmit(takeTable)} className={'d-flex gap-2 align-items-center mb-2'}>
            <CustomDatePicker formController={formController}
                              label={"Дата от"}
                              value={start}
                              name={`start`}
                              rules={{required: true}}/>
            <CustomDatePicker formController={formController}
                              label={"Дата до"}
                              value={end}
                              name={`end`}
                              rules={{required: true}}/>
            <LoadingButton title={"Получить данные"}
                           disabled={loading}
                           loading={loading}/>
          </form>
          <Form.Label className={'d-flex align-items-center gap-2'}>
            <input
              defaultChecked={autoLoad}
              checked={autoLoad}
              onChange={e => {
                setAutoLoad(e.target.checked)
              }}
              type={"checkbox"}
            />{" "}
            Автозагрузка
          </Form.Label>
        </div>
        <BlockUi blocking={loading}>
          {data && <Tabs defaultActiveKey="missedBookings">
            {Object.keys(data).map((key, i) => {
              return <Tab
                eventKey={key}
                key={i}
                title={<div>{tabsName[key]?.name} ({data[key].length})
                  {(tabsName[key]?.alertInfo && data[key].length > 0) &&
                    <i className="fa fa-exclamation-circle text-danger ml-2"></i>
                  }</div>}
              >
                <Table striped bordered hover>
                  <thead>
                  <tr>
                    <th>Индивидуальный код</th>
                    <th>Создано</th>
                    <th>Гость</th>
                    <th>Маршрут</th>
                    <th>Сумма</th>
                    {(key === 'missedBookings' || key === 'canceledBookings' || key === 'unpaidBookings' || key === 'canceledAndMissed') &&
                      <th></th>}
                  </tr>
                  </thead>
                  <tbody>
                  {data[key].map((order, index) => {
                    return <tr key={index}>

                      <td>
                        <div className={'d-flex justify-content-between'}>
                          <div>
                            {(key === 'canceledBookings' || key === 'correctBookins') ?
                              <Link to={TouristServicesEditRoute + order.invoiceId}
                                    target={'_blank'}>{order.orderId}</Link> :
                              <>{order.orderId}</>}
                          </div>
                        </div>
                      </td>
                      <td>{order.createdAt}</td>
                      <td>{order.paxes.join(',\n')}</td>
                      <td>
                        {order.route.start}<br/>
                        {order.route.end}
                      </td>
                      <td>{numberFormat(+order.price)}</td>
                      {(key === 'missedBookings' || key === 'unpaidBookings' || key === 'canceledAndMissed') && <td>
                        <input id={'input_field'}
                               defaultChecked={addToInvoise.includes(order.orderId)}
                               onChange={(e) => {
                                 if (e.target.checked) {
                                   setAddToInvoise(other => ([...other, order.orderId]))
                                 } else {
                                   const newArr = _.filter(addToInvoise, (o) => {
                                     return o !== order.orderId
                                   })
                                   setAddToInvoise(newArr)
                                 }
                               }}
                               type={"checkbox"}
                        />{" "}

                      </td>}
                      {key === 'canceledBookings' && <td>
                        <Link to={{
                          pathname: TouristServicesEditRoute + order.invoiceId,
                          search: `?canceled=${order.price}&id=${order.orderId}`
                        }}
                              target={'_blank'} className={'d-flex gap-2 text-danger align-items-center'}>
                          <i className="fa fa-window-close"/>
                          <div>Отменить</div>
                        </Link>
                        {/*<div style={{cursor: 'pointer'}}*/}
                        {/*     className={'text-danger d-flex align-items-center gap-2'}*/}
                        {/*     onClick={() => {*/}
                        {/*       canseledInBase(order.mongoId)*/}
                        {/*     }}>*/}
                        {/*  <i className="fa fa-window-close"/>*/}
                        {/*  <div>Отменить</div>*/}
                        {/*</div>*/}

                      </td>}

                    </tr>

                  })}
                  {(key === 'missedBookings' || key === 'unpaidBookings' || key === 'canceledAndMissed') && <tr>
                    <td colSpan={9}>
                      <div className={'d-flex justify-content-end gap-2 w-100 align-items-center'}>
                        {/*<Link to={TouristServicesCreateRoute}*/}
                        {/*      state={addToInvoise}*/}
                        {/*      target={'_blank'}*/}
                        {/*      onClick={() => localStorage.setItem("items",  JSON.stringify(addToInvoise))}*/}
                        {/*      className={'btn bg-primary text-white'}>Создать тур-инвойс с выбранными услугами</Link>*/}
                        <Button variant={'primary'} onClick={createTouristServ}>
                          Создать тур-инвойс с выбранными услугами
                        </Button>
                      </div>
                    </td>
                  </tr>}
                  </tbody>
                </Table>
              </Tab>
            })
            }
          </Tabs>}
        </BlockUi>
      </PortletBody>
    </Portlet>
  );
};

export default SinkIWay;
