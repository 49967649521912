import React, {useEffect, useState} from 'react';
import {Portlet, PortletBody, PortletFooter, PortletHeader} from "../../../components/Portlet";
import {Col, Form, Row} from "react-bootstrap";
import {useController} from "react-hook-form";
import ApiSelect from "../../base/components/forms/apiSelect";
import _ from "lodash";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import HookDatePicker from "../../../components/hookDatePicker";
import FormHookSelect from "../../air/airBooking/components/formHookSelect";
import TicketsFields, {checkDate} from "./TicketsFields";
import moment from "moment";
import CustomDatePicker from "../../base/components/forms/customDatePicker";
import IssuedBySelect from "../../air/airBooking/components/issuedBySelect";
import axios from "../../../helpers/axios";
import MoveTickets from "./moveTickets";
import sayError from "../../../helpers/sayError";
import SearchSelect from "../../../components/searchSelect";

const RailWaisOrdersItemForm = ({order, formController, prefix, key, index, removeOrder, arrayToSelect, addOtherTickets}) => {
  const [suppliers, setSuppliers] = useState()
  const [emplArray, setEmplArray] = useState()

  useEffect(() => {
    axios.post('/api/counterparty/suppliers/getAll', {type: 'railway'}).then(({data})=>{
      setSuppliers(data.data)
      console.log('data',data)
    }).catch(sayError)
    axios.post('/api/users/emplSearch', {railwaysAgent: true})
      .then(({data})=>{
        const newArr = data.data.map(user=>{
          return {label: user.label, value: user.value._id}
        })
        setEmplArray(newArr)
      })
      .catch(sayError)
  }, []);
  const {control, register, formState:{errors}, getValues, setError, setValue} = formController
  const {
    field: { onChange: sellerOnChange, value: sellerValue }
  } = useController({
    name: `${prefix}.seller`,
    control,
    rules: { required: true },
    defaultValue: order.seller
  });
  const {
    field: { onChange: buyerOnChange, value: buyerValue }
  } = useController({
    name: `${prefix}.buyer`,
    control,
    rules: { required: true },
    defaultValue: order.buyer
  });
  const {
    field: { onChange: issuedByOnChange, value: issuedByValue }
  } = useController({
    name: `${prefix}.issuedBy`,
    control,
    rules: { required: true },
    defaultValue: order.issuedBy ? order.issuedBy : ''
  });

  return (
    <>
      {suppliers && <Row>
        <Col xs={9}>
          <Portlet>
            <PortletBody>
              <Row className="align-items-center">
                <Col xs="3">
                  <FormHookInput
                    register={register(`${prefix}.orderNumber`, {required: true,})}
                    // placeholder={"Номер бронирования"}
                    label={"Код бронирования"}
                    errors={errors}
                    defaultValue={order.orderNumber}
                  />
                </Col>
                <Col xs="3">
                  <CustomDatePicker formController={formController}
                                    label={"Дата выписки"}
                                    value={order.issueDate}
                                    time
                                    name={`${prefix}.issueDate`}
                                    rules={{required: true}}/>
                </Col>
                <Col xs="4">
                  <FormHookSelect
                    label={'Поставщик'}
                    defaultValue={order.supplier}
                    register={register(`${prefix}.supplier`, { required: true })}
                    errors={errors}
                  >
                    {suppliers.map((s,i)=> {
                      return <option key={i} value={s.value}>{s.label}</option>
                    })}
                  </FormHookSelect>
                </Col>
              </Row>
              <Row className={'mt-3'}>
                <Col xs="4">
                  <ApiSelect
                    value={sellerValue}
                    label={'Продавец'}
                    hasError={_.get(errors, `${prefix}.seller`)}
                    url={"/api/counterparty/searchInput"}
                    queryBase={process.env.REACT_APP_COUNTERPARTIES_FROM_NEWBASE === "true" ? {fromNewBase: true} : {}}
                    cb={newVal => sellerOnChange(newVal)}
                  />
                </Col>
                <Col xs="4">
                  <ApiSelect
                    value={buyerValue}
                    label={'Покупатель'}
                    hasError={_.get(errors, `${prefix}.buyer`)}
                    url={"/api/counterparty/searchInput"}
                    queryBase={process.env.REACT_APP_COUNTERPARTIES_FROM_NEWBASE === "true" ? {fromNewBase: true} : {}}
                    cb={newVal => {
                      buyerOnChange(newVal);
                    }}
                  />
                </Col>
                <Col xs="4">
                  {emplArray && <SearchSelect
                    onChange={(v) => {
                      issuedByOnChange(v)
                    }}
                    defaultValue={issuedByValue||''}

                    hasError={_.get(errors, "issuedBy")}
                    label={'Выписал'}
                    array={emplArray}/>}
                </Col>
              </Row>
              <Row className={'mt-3'}>
                <Col xs={12}>
                  <FormHookInput
                    defaultValue={order.serviceComment}
                    label={'Коментарий для 1с'}
                    register={register(`${prefix}.serviceComment`)}
                  />
                </Col>
              </Row>
              <br/>
              <TicketsFields tickets={order.tickets} formController={formController} prefix={`${prefix}.tickets`}/>
            </PortletBody>
          </Portlet>
        </Col>
        {(order.edit || getValues()?.orders[index]?.issueDate) && <Col>
          <Portlet>
            <PortletHeader
              title={`№${order.orderNumber} - ${order.issueDate}`}/>
            <PortletBody>
              {order.tickets.length > 1 ? order.tickets.map((t, i) => {
                return <p key={i}>
                  {t.firstName} {t.lastName} - №{t.ticketNumber}
                </p>
              }) : <p>
                {order.tickets[0].firstName} {order.tickets[0].lastName} - №{order.tickets[0].ticketNumber}
              </p>}
              {order.edit &&
                <>
                  <p>Создано: <br/> Кем: {order.createdBy.email} <br/>Когда: {order.createdAt} </p>
                </>}
              {/*{!order.edit && arrayToSelect.length>1 &&*/}
              {/*  <MoveTickets array={_.filter(arrayToSelect, (o)=>{return o.value !== order.orderNumber})}*/}
              {/*               addOtherTickets={()=>addOtherTickets(order.tickets)}/> }*/}
            </PortletBody>
            <PortletFooter>
              <div className={'text-danger'}
                   onClick={removeOrder}
                   style={{cursor: 'pointer', textAlign: 'center'}}>
                Удалить бронь <i className={"fa fa-trash"}/>
              </div>
            </PortletFooter>
          </Portlet>
        </Col>}
      </Row>}
    </>

  );
};

export default RailWaisOrdersItemForm;
