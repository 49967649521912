import {Route, Routes} from "react-router-dom";
import {OSVBySupplierRoute, OSVRoute} from "../../../routesConstants";
import OsvPage from "./osv";
import OsvBySupplier from "./osvBySupplier";
import React from "react";


export default function() {
  return (
    <Routes>
      <Route path={'/'}  element={<OsvPage/>} />
      <Route path={'/bySupplier'} element={<OsvBySupplier/>} />
    </Routes>
  );
}
