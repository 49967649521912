import React, {useEffect, useState} from 'react';
import {Portlet, PortletBody} from "../../../components/Portlet";
import axios from "../../../helpers/axios";
import sayError from "../../../helpers/sayError";
import {Form, Tab, Table, Tabs} from "react-bootstrap";
import _ from "lodash"
import {Link} from "react-router-dom";
import {TouristServicesCreateRoute, TouristServicesEditRoute} from "../../routesConstants";
import BlockUi from "@availity/block-ui";
import {useNavigate} from "react-router";
import Button from "react-bootstrap/Button";
import CustomDatePicker from "../../base/components/forms/customDatePicker";
import LoadingButton from "../../base/components/loadingButton";
import moment from "moment";
import {useForm} from "react-hook-form";
import SaveDocBtn from "../../touristServices/components/saveDocBtn";
import numberFormat from "../../../helpers/numberFormat";


export const tabsName = {
  missedBookings: {name:'Нет в базе',alertInfo:true},
  canceledBookings: {name:'Отмененные',alertInfo:false},
  unpaidBookings: {name:'Неоплаченные',alertInfo:false},
  correctBookins: {name:'Правильные',alertInfo:false},
  canceledAndMissed: {name:'Отмененные и ненайденные',alertInfo:true},
  unidentified: {name:'Неопознаные',alertInfo:false}
}
const SinkRateHawk = ({active}) => {
  const start= moment().subtract(7,'d').format('DD.MM.YYYY')
  const end = moment().format('DD.MM.YYYY')
  const [autoLoad,setAutoLoad]= useState(false)
  const formController = useForm({
    start,end
  })
  const {handleSubmit,getValues } = formController
  const [date, setDate] = useState();
  const [loading, setLoading] = useState(false)
  const [table, setTable] = useState(null)
  const [addToInvoise, setAddToInvoise] = useState([])
  const renderStatusIcon = (status)=>{
    if(status === 'completed') {
      return <i className="fa fa-check-square text-success" style={{marginRight:"0.5rem"}} />
    } else if (status === 'cancelled') {
      return <i className="fa fa-window-close text-danger" style={{marginRight:"0.5rem"}}/>
    } else {
      return <i className="fa fa-question text-warning" style={{marginRight:"0.5rem"}} />
    }
  }
  const takeTable = (period) => {
      console.log(period)
      setLoading(true)
      setDate(period)
      axios.post('/api/touristServices/ratehawk/revise',period)
        .then(({data})=>{
          console.log(data)
          setTable(data)
          setAddToInvoise([])
          document.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = false);
        })
        .finally(()=>setLoading(false))

  }
  const onFocus = () => {
    console.log('focus')
    takeTable(getValues());
  };

  useEffect(() => {
    if(active && autoLoad){
      window.addEventListener("focus", onFocus);
      // Calls onFocus when the window first loads
      console.log('first')
      // Specify how to clean up after this effect:
      return () => {
        window.removeEventListener("focus", onFocus);
      };
    }

  }, [active,autoLoad]);


  const canseledInBase =(mongoId) =>{
    if(window.confirm('Вы уверены что хотите отменить?')){
      setLoading(true)
      axios.post("/api/touristServices/ratehawk/markCancelled",{mongoId})
        .then(()=>{
          takeTable(date)
        })
    }
  }
  const createTouristServ = () =>{
    const without = _.find(addToInvoise, (o)=>{return o.withoutDocs !== null})?.withoutDocs
    console.log(without)
    if(_.find(addToInvoise,(o)=>{return o.withoutDocs !==without && o.withoutDocs !== null})){
      window.alert('В одном тур-инвойсе могут быть услиги только с одним типом документов: "С документами" или "Без Документов"')
    }else{
      localStorage.setItem("items",  JSON.stringify(addToInvoise))
      window.open(TouristServicesCreateRoute,'_blank')
    }
  }


  console.log('auto', autoLoad)

  return (
    <Portlet>
      <PortletBody>
        <div className={'d-flex justify-content-between align-items-center'}>
          <form onSubmit={handleSubmit(takeTable)} className={'d-flex gap-2 align-items-center mb-2'}>
            <CustomDatePicker formController={formController}
                              label={"Дата от"}
                              value={start}
                              name={`start`}
                              rules={{required: true}}/>
            <CustomDatePicker formController={formController}
                              label={"Дата до"}
                              value={end}
                              name={`end`}
                              rules={{required: true}}/>
            <LoadingButton title={"Получить данные"}
                           disabled={loading}
                           loading={loading}/>
          </form>
          <Form.Label className={'d-flex align-items-center gap-2'}>
            <input
              defaultChecked={autoLoad}
              checked={autoLoad}
              onChange={e=>{
                setAutoLoad(e.target.checked)
              }}
              type={"checkbox"}
            />{" "}
            Автозагрузка
          </Form.Label>
        </div>

        {table &&
          <BlockUi blocking={loading}>
            <Tabs defaultActiveKey="missedBookings">
              {Object.keys(table).map((key, i) => {
                return <Tab
                  eventKey={key}
                  key={i}
                  title={<div>{tabsName[key].name} ({table[key].length})
                    {(tabsName[key].alertInfo && (key === 'canceledAndMissed' ?!!_.find(table[key],(r)=>r.amount_payable>0 ) :table[key].length > 0)) &&
                      <i className="fa fa-exclamation-circle text-danger ml-2"></i>
                    }</div>}
                >
                  <Table striped bordered hover>
                    <thead>
                    <tr>
                      <th>Статус RH | Индивидуальный код</th>
                      <th>Создано</th>
                      <th>Отель</th>
                      <th>Период</th>
                      <th>Гость</th>
                      <th>Сумма</th>
                      <th>Док.</th>
                      <th>О</th>
                      {(key === 'missedBookings' || key === 'canceledBookings' || key === 'unpaidBookings' || key === 'canceledAndMissed') &&
                        <th></th>}
                    </tr>
                    </thead>
                    <tbody>
                    {table[key].map((order, index) => {
                      return <tr key={index}>

                        <td>
                          <div className={'d-flex justify-content-between'}>
                            <div>
                              {(key === 'canceledBookings' || key === 'correctBookins') ?
                                <Link to={TouristServicesEditRoute + order.invoiceId}
                                      target={'_blank'}>{renderStatusIcon(order.status)}{order.orderId}</Link> :
                                <>{renderStatusIcon(order.status)}{order.orderId}</>}
                            </div>

                          </div>

                        </td>
                        <td>{order.createdAt}</td>
                        <td style={{textTransform: 'capitalize'}}>{order.hotelId.replace(/_/g, ' ')}</td>
                        <td>{order.dates}</td>
                        <td>{order.mainPax}</td>
                        <td>{numberFormat(+order.amount_payable)}</td>
                        <td>{order.withoutDocs === null ? <i className="fa fa-question"/> : !order.withoutDocs &&
                          <i className="fa fa-file-text" aria-hidden="true"/>}</td>
                        <td>{order.office === "B2B-209989" ? <i className="fa fa-font"/> :
                          <i className="fa fa-header"/>}</td>
                        {(key === 'missedBookings' || key === 'unpaidBookings'|| key === 'canceledAndMissed') && <td>
                          <input id={'input_field'}
                                 defaultChecked={_.find(addToInvoise, {orderId: order.orderId})}
                                 onChange={(e) => {
                                   if (e.target.checked) {
                                     setAddToInvoise(other => ([...other, {
                                       orderId: order.orderId,
                                       hotelId: order.hotelId,
                                       office: order.office,
                                       withoutDocs: order.withoutDocs
                                     }]))
                                   } else {
                                     const newArr = _.filter(addToInvoise, (o) => {
                                       return o.orderId !== order.orderId
                                     })
                                     setAddToInvoise(newArr)
                                   }
                                 }}
                                 type={"checkbox"}
                          />{" "}

                        </td>}
                        {key === 'canceledBookings' && <td>
                          <Link to={{
                            pathname: TouristServicesEditRoute + order.invoiceId,
                            search: `?canceled=${order.amount_payable}&id=${order.orderId}`
                          }}
                                target={'_blank'} className={'d-flex gap-2 text-danger align-items-center'}>
                            <i className="fa fa-window-close"/>
                            <div>Отменить</div>
                          </Link>
                          {/*<div style={{cursor: 'pointer'}}*/}
                          {/*     className={'text-danger d-flex align-items-center gap-2'}*/}
                          {/*     onClick={() => {*/}
                          {/*       canseledInBase(order.mongoId)*/}
                          {/*     }}>*/}
                          {/*  <i className="fa fa-window-close"/>*/}
                          {/*  <div>Отменить</div>*/}
                          {/*</div>*/}

                        </td>}

                      </tr>

                    })}
                    {(key === 'missedBookings' || key === 'unpaidBookings' || key === 'canceledAndMissed') && <tr>
                      <td colSpan={9}>
                        <div className={'d-flex justify-content-end gap-2 w-100 align-items-center'}>
                          {/*<Link to={TouristServicesCreateRoute}*/}
                          {/*      state={addToInvoise}*/}
                          {/*      target={'_blank'}*/}
                          {/*      onClick={() => localStorage.setItem("items",  JSON.stringify(addToInvoise))}*/}
                          {/*      className={'btn bg-primary text-white'}>Создать тур-инвойс с выбранными услугами</Link>*/}
                          <Button variant={'primary'} onClick={createTouristServ}>
                            Создать тур-инвойс с выбранными услугами
                          </Button>
                        </div>
                      </td>
                    </tr>}
                    </tbody>
                  </Table>
                </Tab>
              })
              }
            </Tabs>
          </BlockUi>
        }

      </PortletBody>

    </Portlet>
  );
};

export default SinkRateHawk;
