import React, {useEffect, useState} from 'react';
import {Portlet, PortletHeader} from "../../../components/Portlet";
import BlockUi from "@availity/block-ui";
import axios from "../../../helpers/axios";
import {Link} from "react-router-dom";
import {CheckRoute, TouristServicesListRoute} from "../../routesConstants";

const keyTransl = {
  "totalBadCounterparty": {name:'Неккоректные по покупателю касса', url:CheckRoute},
  "totalBadServiceItem": {name:'Неккоректные по услугам касса', url:CheckRoute},
  "unpaidOrders": {name:'Неоплаченных услуг', url:CheckRoute},
  "iWay": {name:'Нужно разнести в iWay', url:TouristServicesListRoute +'#iWay'},
  "rateHawk": {name:'Нужно разнести в RateHawk', url:TouristServicesListRoute+'#sink'},
  "servicesNotFound": {name:'Нет трат за услуги в кассе', url: TouristServicesListRoute}
}

const CheckAllInfo = () => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [message, setMessage] = useState()


  useEffect(() => {
    axios.post("/api/dashboard/checkAllInfo")
      .then(({data})=>{
        console.log(data)
        if(!data.data){
          setMessage(data.message)
        }
        setData(data.data)
      }).finally(()=>setLoading(false))

  }, []);

  return (
    <Portlet>
      <PortletHeader title={'Есть ли проблемы за 2 мес.?'}/>
      <BlockUi blocking={loading}>
        {data && Object.keys(data).map((key,i)=>{
          return <Link to={keyTransl[key].url} target={'_blank'} key={i}
                      className={`d-flex justify-content-between align-items-center  ${data[key] > 0 ? 'serviceCardError' : 'serviceCard'}`}>
            <div>{keyTransl[key].name}</div>
            <div>{data[key]}</div>
          </Link>
        })}
        {message && <div className={'text-center'}>{message}</div>}
      </BlockUi>
    </Portlet>
  );
};

export default CheckAllInfo;
