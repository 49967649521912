import React, {useState} from "react";
import Form from "react-bootstrap/esm/Form";
import {Col, Row, Badge, Button, Tab} from "react-bootstrap";
import {Formik, FieldArray} from "formik";
import CustomDatePicker from "../../../../components/customDatePicker";
import {airClasses, mealTypes, titles} from "../../../../helpers/enums";
import ApiSelect from "../../../base/components/forms/apiSelect";
import InputMask from "react-input-mask";
import {useFieldArray, Controller, useController} from "react-hook-form";
import TripItemHotelRoom from "../../../trips/components/tripHotelRoom";
import HookDatePicker from "../../../../components/hookDatePicker";
import {ErrorMessage} from "@hookform/error-message";
import FormHookInput from "./formHookInput";
import FormHookSelect from "./formHookSelect";
import _ from "lodash";
import FormHookDatePicker from "../../../base/components/forms/formHookDatePicker";
import AirplaneSelector from "../../../base/components/forms/airplaneSelector";
import GetSegmentByFlight from "./getSegmentByFlight";

const AirBookingSegmentItemForm = ({
                                     segment,
                                     removeItem,
                                     index,
                                     prefix,
                                     formController
                                   }) => {
  const {
    register,
    watch,
    control,
    setValue,
    getValues,
    setError,
    formState: {errors}
  } = formController;

  const TIME_MASK = [/^([0-2])/, /([0-9])/, ":", /[0-5]/, /[0-9]/];

  const deleteSegment = () => {
    removeItem();
  };
  const {
    field: {onChange: flightTimeOnChange, value: flightTimeValue}
  } = useController({
    name: `${prefix}.flightTime`,
    control: formController.control,
    defaultValue: segment.flightTime,
    rules:{required:true,
      validate: (value, formValue) => {
      if(value === "00:00"){
        setError(`${prefix}.flightTime`,{
          type: 'required',
          message: "Время в полете не может быть равно 00:00"
        })
        return false
      }
      return true
      }
    }
  });
  const {
    field: {onChange: airplaneOnChange, value: airplaneValue}
  } = useController({
    name: `${prefix}.airplane`,
    control: formController.control,
    defaultValue: segment.airplane
  });
  console.log("renderreeeer");
  return (
    <Row className="align-items-center segmentContainer mb-4">
      {/*<Col xs="1">*/}
      {/*  № {index+1} <i className={"la la-times"} onClick={deleteSegment}/>*/}
      {/*</Col>*/}

      <Col xs="12" className={'mb-3'}>
        <div className={"inlineContainer"}>
          <span style={{width: "4%"}}>{index + 1}.</span>
          <input
            type={"hidden"}
            register={register(`${prefix}.tempId`)}
            defaultValue={segment.tempId}
          />
          <input
            type={"hidden"}
            defaultValue={segment.id}
            {...register(`${prefix}.id`)}
          />
          <FormHookInput
            label={'Авиа'}
            register={register(`${prefix}.airline`, {
              required: true,
              maxLength: 2,
              minLength: 2,
              onChange: e =>
                (e.target.value = ("" + e.target.value).toUpperCase())
            })}
            defaultValue={segment.airline}
            // placeholder={"Авиа"}
            onChange={e => {
              e.target.value = ("" + e.target.value).toUpperCase();
            }}
            style={{width: "8%"}}
            errors={errors}
          />
          <FormHookInput
            defaultValue={segment.flightNumber}
            // placeholder={"Номер"}
            label={'Номер'}
            style={{width: "14%"}}
            register={register(`${prefix}.flightNumber`, {
              required: true,
              maxLength: 4,
              minLength: 3
            })}
            errors={errors}
          />
          <FormHookInput
            register={register(`${prefix}.departure.airport`, {
              required: true,
              maxLength: 3,
              minLength: 3,
              onChange: e =>
                (e.target.value = ("" + e.target.value).toUpperCase())
            })}
            defaultValue={segment.departure.airport}
            label={'Вылет'}
            style={{width: "9%"}}
            errors={errors}
          />
          <FormHookInput
            register={register(`${prefix}.arrival.airport`, {
              required: true,
              maxLength: 3,
              minLength: 3,
              onChange: e =>
                (e.target.value = ("" + e.target.value).toUpperCase())
            })}
            defaultValue={segment.arrival.airport}
            label={'Прилет'}
            // placeholder={"Прилет"}
            style={{width: "9%"}}
            errors={errors}
          />
          <div className={"inlineContainer"} style={{width: "28%", position: "relative"}}>
            <label className={'absolutLabel'}>{'Дата и время вылета'}</label>
            <FormHookDatePicker
              formController={formController}
              dateRules={{required: true}}
              date={segment.departure.date}
              dateName={`${prefix}.departure.date`}
              timeRules={{required: true}}
              time={segment.departure.time}
              timeName={`${prefix}.departure.time`}
              errors={errors}
            />
          </div>
          <div className={"inlineContainer"} style={{width: "28%",position: "relative"}}>
            <label className={'absolutLabel'}>{'Дата и время прилета'}</label>
            <FormHookDatePicker
              formController={formController}
              dateRules={{required: true}}
              date={segment.arrival.date}
              dateName={`${prefix}.arrival.date`}
              timeRules={{required: true}}
              time={segment.arrival.time}
              timeName={`${prefix}.arrival.time`}
              errors={errors}
            />
          </div>
        </div>
      </Col>

      <Col md={{span: 12}}>
      <div className={"inlineContainer"}>
          <span style={{width: "4%"}}>
            <i className={"la la-times"} onClick={deleteSegment}/>
          </span>

          <FormHookInput
            defaultValue={segment.flightClass}
            register={register(`${prefix}.flightClass`, {required: true})}
            label={'Класс'}
            style={{width: "8%"}}
            errors={errors}
          />
          <FormHookSelect
            defaultValue={segment.classType}
            label={'Тип класса'}
            register={register(`${prefix}.classType`, {required: true})}
            errors={errors}
            style={{width: "14%"}}
          >
            {airClasses.map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}
          </FormHookSelect>
          <FormHookInput
            defaultValue={segment.departure.terminal}
            register={register(`${prefix}.departure.terminal`)}
            style={{width: "15%"}}
            label={'Терм. Вылета'}
            // placeholder={"Терминал вылета"}
            errors={errors}
          />
          <FormHookInput
            defaultValue={segment.arrival.terminal}
            register={register(`${prefix}.arrival.terminal`)}
            style={{width: "17%", paddingRight:23}}
            label={'Терм. Прилета'}
            errors={errors}
          />
        <div style={{width: "15%", position: 'relative'}}>
          <label className={'absolutLabel'}>{'Время в полете'}</label>
          <InputMask
            mask={TIME_MASK}
            value={flightTimeValue}
            onChange={flightTimeOnChange}
            className={"form-control"}
            style={
              errors.segments?.length > 0 && (!flightTimeValue || flightTimeValue === "00:00")
                ? {
                  borderColor: "#FD397A"
                }
                : {}
            }
            // placeholder={"В пути"}
          />
        </div>

        {/*{console.log("airplaneRendereer")}*/}
        <div style={{width:'27%'}}>
          <AirplaneSelector
            width={'100%'}
            errors={errors}
            label={'Самолет'}
            name={`${prefix}.airplane`}
            formController={formController}
            value={airplaneValue}/>
        </div>
        </div>
      </Col>
    </Row>
  );
};

const AirBookingSegmentsForm = ({
                                  data,
                                  formController,
                                  prefix,
                                  segmentsController
                                }) => {
  const {control, formState: {errors}} = formController;
  const {fields} = segmentsController
  const [tempId, setTempId]=useState(1)
  let segmentsControl = segmentsController;
  const segmentTemplate = {
    flightNumber: "",
    airline: "",
    flightTime: "",
    flightClass: "",
    classType: "",
    arrival: {
      terminal: "",
      airport: "",
      date: ""
    },
    departure: {
      terminal: "",
      airport: "",
      date: ""
    }
  };
  const uniqSegmentIds = fields.map(u => u.tempId);
  const addSegment = (data) => {
    const tempId = uniqSegmentIds.length>0 ? _.max(uniqSegmentIds)+1 : 1
    const newSegment = {...data, tempId};
    // let newIdFound = false;
    //   if (uniqSegmentIds.indexOf(tempId) === -1) {
    //     newSegment.tempId = tempId;
    //     newIdFound = true;
    //   }
    //   setTempId(id=>id++);
    segmentsControl.append(newSegment);
  };
  const removeSegment = index => {
    // const formValues = formController.getValues();
    // const currentSegment = formValues.segments[index];
    // formValues.paxes.forEach(pax => {
    //   pax.tickets.forEach(ticket => {
    //     ticket.segments.forEach((segment, index, segmentsArray) => {
    //       if (segment.segment === currentSegment.tempId) {
    //         segmentsArray.splice(index, 1);
    //       }
    //     });
    //   });
    // });
    // formValues.segments.splice(index, 1);
    // formController.reset(formValues);
    segmentsControl.remove(index);
  };
  return (
    <>
      <Row>
        <Col sm={12}>
          <Form.Group className={"formTitle"}>
            <h6 className={'mb-3'}>
              Сегменты{" "}
              <Badge onClick={()=>addSegment(segmentTemplate)} variant="success">
                <i className={"la la-plus"}/> Добавить
              </Badge>
              <GetSegmentByFlight addSegment={(segment)=>addSegment(segment)}/>
            </h6>
            {segmentsControl.fields.map((segment, index) => {
              return (
                <AirBookingSegmentItemForm
                  segment={segment}
                  key={segment.customId}
                  index={index}
                  removeItem={() => removeSegment(index)}
                  prefix={`${prefix}[${index}]`}
                  formController={formController}
                />
              );
            })}
          </Form.Group>
          {(_.get(errors, `${prefix}`)?.root) ? (
            <p className={"text-danger"}>Минимум 1 сегмент</p>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default AirBookingSegmentsForm;
