import React, {useContext, useEffect, useState} from 'react';
import {useController, useFieldArray} from "react-hook-form";
import {Col, Form, Row, Tab, Tabs} from "react-bootstrap";
import _ from "lodash";
import axios from "../../../helpers/axios";
import ServiceItem from "./ServiceItem";
import moment from "moment/moment";
import * as uuid from "uuid";
import serviceContext from "./serviceContext";
import {useLocation} from "react-router-dom";


export const serviceTemplate = {
  mainPax:'',
  totalpaxes:1,
  commission:0,
  krsKayar:0,
  discription:'',
  discount:0,
  total:0,
  canceled:false,
  idInOneC:'',
  supplier:process.env.REACT_APP_DEFAULT_WITHOUTDOC_SUPPLIER,
  country:'',
  currency:process.env.REACT_APP_DEFAULT_CURRENCY,
  exchangeRate:1,
  startDate:moment().format('DD.MM.YYYY'),
  endDate:moment().format('DD.MM.YYYY'),
  type: '',
  summCurrency:0,
  summKZT:0,
  quantity:1,
  amountSell:0,
  documents:[],
  dontSearchInTable:false
}

const ServicesFields = ({formController,services,types, edit, fromParser, nameCount}) => {
  const { search } = useLocation();
  const {control, formState: {errors}, getValues, watch, setValue} = formController
  const [countries, setContries] = useState(false)
  const [suppliers, setSuppliers] = useState()
  const [loading, setLoading] = useState(true)
  const [activeTab, setActiveTab] = useState('ser0')
  const {serviceItems,setServiceItems} = useContext(serviceContext)

  const servicesControl = useFieldArray({
    control,
    name: `serviceItems`,
    rules: {required: true},
    keyName: 'customId',
    defaultValue: services
  });
  useEffect(() => {
    axios.post('/api/locations/countries/searchInput')
      .then(({data})=>{
        setContries(data.data)
      })
      .catch(e=>{
        window.alert(e.response.data.message[0])
        console.log('err',e)
      })
    axios.post('/api/counterparty/suppliers/getAll',{type: 'service'}).then(({data}) => {
      setSuppliers(data.data)
    });

    servicesControl.replace(services)
    if(search){
      const url = new URLSearchParams(search)
      const servId= url.get('id')
      const index = _.findIndex(services, {idInOneC: servId})
      console.log('index',index,url.get('id'),services)
      setActiveTab(`ser${index}`)
      const needServ = {...services[index],
        canceled:true,
        amountSell:+services[index].summCurrency,
        summKZT: +url.get('canceled'),
        summCurrency: +url.get('canceled')}
      servicesControl.update(index, needServ)
      let arr = services.slice()
      arr[index]=needServ
      console.log('new', arr)
      setServiceItems(arr)
    }
    setLoading(false)
  }, []);

  console.log('values', getValues())
  const deleteService = (index) => {
    if(window.confirm('Вы уверены, что хотите удалить услугу?')){
      servicesControl.remove(index);
      console.log('splice',serviceItems,index)
      serviceItems.splice(index,1)
      if (servicesControl.fields.length - 1) {
        setActiveTab('ser0');
      }
    }
  };
  const updateItemFromRH = (index, data) =>{
    console.log("index", index, data)
    if(data.withoutDocs){
      setValue('issuedWithoutDocs', true)
    }
    servicesControl.update(index, data)
  }
  const lenght = watch(`serviceItems`, [])
  const addService = () => {
    servicesControl.append({...serviceTemplate});
    setActiveTab(`ser${lenght.length}`);
    setServiceItems([...serviceItems, serviceTemplate])
  };
  const copyServ = (index)=>{
    const copyedRoom = getValues().serviceItems[index]
    const newServ = {...copyedRoom}
    delete newServ.id
    console.log('uuid.validate(copyedRoom.idInOneC)',uuid.validate(copyedRoom.idInOneC))
    if(uuid.validate(newServ.idInOneC) || newServ.idInOneC.includes('-')){
      const codeType = _.find(types,{id: newServ.type})?.code
      let random = Math.floor(Math.random()*10000)
      newServ.idInOneC = moment().format('YYMMDD')+'-'+codeType+ String(random).padStart(4, '0')
    }
    servicesControl.append({...newServ});
    setActiveTab(`ser${lenght.length}`);
    setServiceItems([...serviceItems, newServ])
  }
  const tabClick = (key) => {
    if (key === "add") {
      addService()
    } else {
      setActiveTab(key);
    }
  };

  const setTempValue = (index) =>{
    const newData  = {
      ...serviceTemplate,
      mainPax:nameCount,
      idInOneC:uuid.v4(),
      country:process.env.REACT_APP_DEFAULT_COUNTRY,
      type: process.env.REACT_APP_DEFAULT_TOURTYPE,
      optionalFields: {comment : "Неопознанная туруслуга"}
    }
    setValue('issuedWithoutDocs', true)
    servicesControl.update(index, newData)
  }
  const watchFieldArray = watch("serviceItems");
  const controlledFields = servicesControl.fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index]
    };
  });


  return (
    <>
      <h6>Информация по услугам ({servicesControl.fields.length})</h6>
      <Tabs
        variant="pills"
        className={"paxTabs"}
        id="controlled-tab-example"
        activeKey={activeTab}
        onSelect={key => tabClick(key)}
      >
        {types && suppliers &&!loading && controlledFields.map((serv,index)=>{
          return <Tab
            eventKey={`ser${index}`}
            key={serv.customId}
            title={`Услуга №${index+1}`}
            tabClassName={
              _.get(errors, `serviceItems[${index}]`) ? "is-invalid" : ""
            }
          >
            <ServiceItem prefix={`serviceItems[${index}]`}
                         serv={serv}
                         types={types}
                         countries={countries}
                         edit={edit}
                         nameCount={nameCount}
                         setTempValue={()=>setTempValue(index)}
                         suppliers={suppliers}
                         fromParser={fromParser}
                         deleteService={()=>deleteService(index)}
                         update={(data)=>updateItemFromRH(index, data)}
                         copy={()=>copyServ(index)}
                         formController={formController}/>
          </Tab>
        })}
        <Tab eventKey={"add"} title={<i className={"la la-plus"}/>}/>
      </Tabs>

    </>
  );
};

export default ServicesFields;
