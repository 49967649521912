import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Portlet, PortletHeader, PortletHeaderToolbar} from "../../../../components/Portlet";
import CustomDatePicker from "../../../base/components/forms/customDatePicker";
import moment from "moment/moment";
import FormHookInput from "../../../air/airBooking/components/formHookInput";
import LoadingButton from "../../../base/components/loadingButton";
import {useController, useForm} from "react-hook-form";
import SearchSelect from "../../../../components/searchSelect";
import axios from "../../../../helpers/axios";
import {Table} from "reactstrap";
import BuyerRowWithTable from "../../../touristServices/components/buyerRowWithTable";
import _ from "lodash";

const OsvBySupplier = () => {
  const [loading,setLoading] = useState(false)
  const [suppliers, setSupliers] =  useState()
  const [data, setData] = useState()
  const formController = useForm()
  const {handleSubmit,
    control,
    getValues,
    register,
    formState:{errors}} = formController

  useEffect(() => {
    axios.post('/api/counterparty/baseSuppliers')
      .then(({data})=>{
        setSupliers(data.data)
      })
  }, []);

  const {
    field: { onChange: supplierOnChange, value: supplierValue }
  } = useController({
    name: `supplier`,
    control,
    rules: { required: true },
    defaultValue: ''
  });
  const onSubmit = (data) =>{
    setLoading(true)
    axios.post('/api/fromOneC/osvBySupplier', data)
      .then(({data})=>{
        setData(_.sortBy(data.data, (buyer)=>_.sumBy(buyer.tableData, (r)=>(r.netto + r.krs + r.krsKayar)*-1)))
      })
      .finally(()=>setLoading(false))
  }
  return (
    <>
      <Helmet>
        Осв по поставщику
      </Helmet>
      <Portlet>
        <PortletHeader title="Осв по поставщику" toolbar={<PortletHeaderToolbar>
          {suppliers && <form onSubmit={handleSubmit(onSubmit)} className={'d-flex align-items-center gap-2'}>
            <CustomDatePicker formController={formController}
                              label={"Дата c"}
                              value={moment().subtract(7, 'days').format('DD.MM.YYYY')}
                              name={`start`}
                              rules={{required: true}}/>
            <CustomDatePicker formController={formController}
                              label={"Дата по"}
                              value={moment().format('DD.MM.YYYY')}
                              name={`end`}
                              rules={{required: true}}/>
            <div style={{width: 200}}>
              <SearchSelect
                onChange={(v) => {
                  supplierOnChange(v)
                  console.log(v)
                }}
                label={'Поставщик'}
                unregister
                array={suppliers}/>
            </div>
            <LoadingButton loading={loading} title={'Получить'}/>
          </form>}
        </PortletHeaderToolbar>}/>
        {data && <div className={'tableFixOnlyHead'}>
          <Table bordered size={'sm'}>
            <thead>
              <tr>
                <th rowSpan={2} colSpan={2} >Контрагент</th>
                <th rowSpan={2}>Ком.прод</th>
                <th colSpan={2}>Сальдо нач</th>
                <th colSpan={3}>Обороты</th>
                <th>Сальдо кон</th>
              </tr>
              <tr>
                <th>Дебет</th>
                <th>Кредит</th>
                <th>Дебет пост.</th>
                <th>Дебет</th>
                <th>Кредит</th>
                <th>Дебет</th>
              </tr>
            </thead>
            <tbody>
              {data.map((counterparty,index)=>{
                return <React.Fragment key={index}>
                  <BuyerRowWithTable buyer={counterparty} end={getValues().end} start={getValues().start}/>
                </React.Fragment>
              })}
            </tbody>
          </Table>
        </div>}
      </Portlet>

    </>
  );
};

export default OsvBySupplier;
