import React, {useEffect, useState} from 'react';
import axios from "../helpers/axios";
import _, {values} from "lodash";
import Select from "react-select";

const SearchSelect = ({defaultValue, array, onChange,label, hasError, style,isMulti,unregister}) => {
  useEffect(() => {
  }, [defaultValue]);
  const plusStyle = style ||{}
  const findValue = () =>{
    let defaultVal = isMulti ?
      defaultValue.map(r=>(_.find(array,{'value': r})))
      : _.find(array, {'value': defaultValue})
    if(_.find(array, {'label': 'Все'}) && !defaultVal){
      defaultVal = _.find(array, {'label': 'Все'})
    }
    return defaultVal
  }

  const formatOptionLabel = ({ value, label,  }) => (
    <div style={{ display: "flex" }}>
      {label}
    </div>
  );

  const styles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: hasError ? '#FD397A' : '#e2e5ec',
      ...plusStyle
    }),
    menu:provided => {
      return {...provided, zIndex: '10000'};
    },
  }

  return (
    <div style={{position: 'relative'}}>
      <label className={'absolutLabel'}>{label}</label>
      {!unregister ? <Select options={array}
                             onChange={(val) => {
                               console.log('test',val)
                               isMulti ? onChange(val.map(v=>(v.value))) :onChange(val.value)
                             }}
                             defaultValue={findValue()}
                             value={findValue()}
                             isMulti={isMulti}
                             styles={styles}
                             formatOptionLabel={formatOptionLabel}/>:
        <Select options={array}
                onChange={(val) => {
                  console.log('test',val)
                  isMulti ? onChange(val.map(v=>(v.value))) :onChange(val.value)
                }}
                defaultValue={findValue()}
                value={findValue()}
                isMulti={isMulti}
                styles={styles}
                formatOptionLabel={formatOptionLabel}/>}

    </div>

  );
};

export default SearchSelect;
