import * as yup from "yup";

export const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required()
    .default(""),
  lastName: yup
    .string()
    .required()
    .default(""),
  operatorCode: yup
    .string().default(""),
  phone: yup.string().default(""),
  telegramUsername: yup.string().default(""),
  railwaysLogin: yup.string().default(""),
  ratehawkLogin: yup.string().default(""),
  email: yup
    .string()
    .email()
    .required()
    .default(""),
  active: yup.boolean().default(true),
  dontSendEmail: yup.boolean().default(false),
  role: yup
    .string()
    .required()
    .default("employee"),
  sendTgNotifications: yup
    .array()
    .of(
      yup.lazy(value =>
        typeof value === "number" ? yup.number() : yup.string()
      )
    ).default([]),
  specialPermissions: yup
    .array()
    .of(
      yup.lazy(value =>
        typeof value === "number" ? yup.number() : yup.string()
      )
    ).default([])
});
