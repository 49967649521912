import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import moment from "moment";


const DateToFromPicker = ({setOptions, label, noTime, clear, defaultValue}) => {

  const [startDate, setStartDate] = useState(defaultValue?.start ?
    moment(defaultValue.start, 'DD.MM.YYYY HH:mm').toDate(): '');
  const [endDate, setEndDate] = useState(defaultValue?.end ?
    moment(defaultValue.end, 'DD.MM.YYYY HH:mm').toDate() : '');
  useEffect(() => {
    if(startDate && endDate){
      noTime ?
        setOptions([moment(startDate).format('DD.MM.YYYY'),moment(endDate).format('DD.MM.YYYY') ])
        :
        setOptions([moment(startDate).format('DD.MM.YYYY HH:mm'),moment(endDate).format('DD.MM.YYYY HH:mm') ])
    }
  }, [startDate, endDate]);


  const clearInputs = () =>{
    setOptions([])
    setStartDate('')
    setEndDate('')
  }

  useEffect(() => {
    if(clear){
      clearInputs()
    }
  }, [clear]);
  return (
    <div style={{position: 'relative'}} className={'d-flex'}>
      <label className={'absolutLabel'}>{label}</label>
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        locale={'ru'}
        calendarStartDay={1}
        startDate={startDate}
        className={"form-control"}
        dateFormat={'dd.MM.yyyy'}
        endDate={endDate}
      />
      <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        className={"form-control"}
        dateFormat={'dd.MM.yyyy'}
        locale={'ru'}
        calendarStartDay={1}
        endDate={endDate}
        minDate={startDate}
      />
      <i className={"fa fa-times-circle text-secondary clearIcon"} onClick={() => clearInputs()}/>
    </div>
  );
};

export default DateToFromPicker;
