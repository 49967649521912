import React, {useEffect, useState} from 'react';
import {Portlet, PortletBody, PortletFooter, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import {useController, useForm, useWatch} from "react-hook-form";
import {Col, Form, Row} from "react-bootstrap";
import ApiSelect from "../../base/components/forms/apiSelect";
import _ from "lodash";
import IssuedBySelect from "../../air/airBooking/components/issuedBySelect";
import ServicesFields from "./ServicesFields";
import CustomDatePicker from "../../base/components/forms/customDatePicker";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import LoadingButton from "../../base/components/loadingButton";
import axios from "../../../helpers/axios";
import FormHookSelect from "../../air/airBooking/components/formHookSelect";
import PaidButton from "./PaidButton";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link, useLocation, useParams} from "react-router-dom";
import {TouristServicesCreateRoute, TouristServicesListRoute} from "../../routesConstants";
import sayError from "../../../helpers/sayError";
import SearchSelect from "../../../components/searchSelect";
import PotentialBuyers from "./potentialBuyers";
import moment from "moment";
import numberFormat from "../../../helpers/numberFormat";
import Button from "react-bootstrap/Button";
import * as uuid from "uuid";
import serviceContext from "./serviceContext";
import GoogleItem from "./googleItem";


const TouristServicesForm = ({data, onSubmit, edit, removeOrder, potentialBuyers, fromParser,reload}) => {
  const {id} = useParams();
  const formController = useForm({shouldUnregister: true, defaultValues:data})
  const [types, setTypes] = useState()
  const [emplArray, setEmplArray] = useState()
  const [nameCount, setNameCount] = useState(null)
  const [renderSumm, setRenderSumm] = useState(false)
  const [serviceItems, setServiceItems] = useState(data.serviceItems)
  useEffect(() => {
    axios.post('/api/touristServices/typesSearch')
      .then(({data})=>{
        console.log('types', data)
        setTypes(data.data)
      })
      .catch(sayError)
    axios.post('/api/users/emplSearch', {operatorOneS: true})
      .then(({data})=>{
        const newArr = data.data.map(user=>{
          return {label: user.label, value: user.value._id}
        })
        setEmplArray(newArr)
      })
      .catch(sayError)
  }, []);
  useEffect(() => {
    if(data){
      setTimeout(()=>setRenderSumm(true),300)
    }
  }, [data]);

  const {control,
    register,
    getValues,
    setValue,
    watch,
    handleSubmit,
  formState:{errors}} = formController
  const {
    field: { onChange: sellerOnChange, value: sellerValue }
  } = useController({
    name: `seller`,
    control,
    rules: { required: true },
    defaultValue: data.seller
  });
  const {
    field: { onChange: buyerOnChange, value: buyerValue }
  } = useController({
    name: `buyer`,
    control,
    rules: { required: true },
    defaultValue: data.buyer
  });
  const {
    field: { onChange: issuedByOnChange, value: issuedByValue }
  } = useController({
    name: `issuedBy`,
    control,
    rules: { required: true },
    defaultValue: data.issuedBy
  });
  console.log('values', getValues(), errors)
  const setBuyer = (id) =>{
    buyerOnChange(id)
  }
  const submitWithOneC = (data) =>{
    onSubmit({...data, importOneS:true})
  }

  const copiedIvoice = () =>{
    const info = {...getValues()}
    info.serviceItems.forEach(s=>{
      if(uuid.validate(s.idInOneC) || s.idInOneC.includes('-')){
        const codeType = _.find(types,{id: s.type})?.code
        let random = Math.floor(Math.random()*10000)
        s.idInOneC = moment().format('YYMMDD')+'-'+codeType+ String(random).padStart(4, '0')
      }
      delete s.id
    })

    localStorage.setItem('servCopied',JSON.stringify(info))
    window.open(window.location.protocol + "//" + window.location.host+TouristServicesCreateRoute)
  }



  return (
    <>
      <serviceContext.Provider value={{ serviceItems, setServiceItems}}>
        <Breadcrumbs aria-label="Breadcrumb" style={{paddingBottom: 15}}>
          <Link to={TouristServicesListRoute}>Список услуг</Link>
          <Typography color="textPrimary">Услуга</Typography>
        </Breadcrumbs>
        <Row>
          <Col xs={9}>
            <Portlet>
              <PortletHeader title={'Загрузить туристическую услугу'}
                             toolbar={edit && data.issuedWithoutDocs &&<PortletHeaderToolbar >
                               <PaidButton  paid={data.paid} url={'/api/touristServices/accountant/markPaid/'+id} cb={reload}/>
                             </PortletHeaderToolbar>}/>
              <form onSubmit={handleSubmit(submitWithOneC)} >
                <PortletBody>
                  {/*<div className={'mb-3'}>При сохранении услуги <b>дата выписки</b> автоматически поменяется на <b>дату окончания услуг</b></div>*/}
                  <Row className={'mb-3'}>
                    <Col xs="3">
                      <ApiSelect
                        value={sellerValue}
                        label={'Продавец'}
                        hasError={_.get(errors, `seller`)}
                        url={"/api/counterparty/searchInput"}
                        cb={newVal => sellerOnChange(newVal)}
                      />
                    </Col>
                    <Col xs="3">
                      <ApiSelect
                        value={buyerValue}
                        label={'Покупатель'}
                        hasError={_.get(errors, `buyer`)}
                        url={"/api/counterparty/searchInput"}
                        cb={newVal => {
                          buyerOnChange(newVal);
                        }}
                        getLabel={(v)=>setNameCount(v)}
                      />
                    </Col>
                    <Col xs="3">
                      <CustomDatePicker formController={formController}
                                        label={"Дата выписки"}
                                        value={data.issueDate}
                                        name={`issueDate`}
                                        rules={{required: true}}/>
                    </Col>
                    <Col xs="3">
                      {emplArray && <SearchSelect
                        onChange={(v) => {
                          issuedByOnChange(v)
                        }}
                        defaultValue={issuedByValue}
                        hasError={_.get(errors, "issuedBy")}
                        label={'Выписал'}
                        array={emplArray}/>}
                    </Col>

                  </Row>
                  <Row className={'mb-3'}>
                    <Col xs={9}>
                      <FormHookInput
                        register={register(`message`)}
                        defaultValue={data.message}
                        label={'Комментарий 1с'}
                        errors={errors}
                      />
                    </Col>
                    <Col>
                      <Form.Label className={'d-flex align-items-center gap-2'}>
                        <input
                          defaultChecked={data.issuedWithoutDocs}
                          {...register(`issuedWithoutDocs`,{
                            onChange:(e)=>{
                              if(e.target.checked){
                                getValues().serviceItems.forEach((s,i)=>{
                                  setValue(`serviceItems[${i}].supplier`, process.env.REACT_APP_DEFAULT_WITHOUTDOC_SUPPLIER )
                                })
                              }
                            }
                          })}
                          type={"checkbox"}
                        />{" "}
                        Без документов
                      </Form.Label>
                      <Form.Label className={'d-flex align-items-center gap-2'}>
                        <input
                          defaultChecked={data.public}
                          {...register(`public`)}
                          type={"checkbox"}
                        />{" "}
                        Публичный
                      </Form.Label>
                    </Col>
                  </Row>
                  <div className={'mb-2'}>Если выбрано <b>"Без документов"/"Website"</b>, то эти услуги официально не
                    проводятся. Оплата только на <b>ЛИЧНЫЕ карты.</b>
                    <br/>
                    <b>Не забудьте предупредить клиента!</b></div>

                  <ServicesFields formController={formController}
                                  edit={edit}
                                  nameCount={nameCount}
                                  fromParser={fromParser}
                                  types={types}
                                  services={data.serviceItems}/>

                </PortletBody>
                <PortletFooter className={'d-flex gap-2 align-items-center'}>
                  <LoadingButton title={"Сохранить"} type={'submit'} loading={false} disabled={edit && data.isDeleted}/>
                  {(edit && data.isDeleted) &&  <>Тур услуга удалена,нельзя редактировать</>}
                  {/*<LoadingButton title={"Сохранить и в 1С"} type={'submit'} loading={false} onClick={handleSubmit(submitWithOneC)}/>*/}
                </PortletFooter>
              </form>


            </Portlet>
          </Col>
          <Col xs={3}>
            {potentialBuyers && <PotentialBuyers potentialBuyers={potentialBuyers}
                                                 setBuyer={setBuyer}/> }
            <Portlet>
              <PortletHeader title={'Общая информация'}/>
              {serviceItems && <><PortletBody>
                <h6>Всего услуг: {getValues().serviceItems.length || 0}</h6>

                {serviceItems.map((s,i)=>{
                  const type = _.find(types, {id:s.type})?.name
                  const sum =(parseInt(s.summKZT)+parseInt(+s.commission)+parseInt(+s.krsKayar))*parseInt(s.quantity)
                  const start = moment(s.startDate, 'DD.MM.YYYY HH:mm').format('DD.MM')
                  const end = moment(s.endDate,'DD.MM.YYYY HH:mm').format('DD.MM')
                  let optionalString = ''
                  if (s.optionalFields){
                    Object.keys(s.optionalFields).forEach((key,i)=>{
                      const optional = key === 'nights' ? 'ноч.' : key === 'days' ? 'дн.' : ''
                      if(s.optionalFields[key] && key !== 'city' && key !=='carrier'){
                        optionalString = `${optionalString}${s.optionalFields[key]} ${optional}, `
                      }
                    })
                  }
                  return <div key={i} className={i !== getValues().serviceItems.length-1 ?'mb-3':''}>
                    <b>{start === end ? start : `${start}-${end}`}<br/></b>
                    {type || 'Выберите услугу'} - {s.mainPax || 'Выберите имя'} {s.totalpaxes > 1 && `+${s.totalpaxes - 1}`}<br/>
                    {optionalString.slice(0, -2)}{optionalString && <br/>}
                    {renderSumm && `Сумма: ${numberFormat(sum)} KZT`}
                  </div>
                })}
              </PortletBody>
                <PortletFooter>
                  {renderSumm && <div>Общая стоимость: {numberFormat(_.sum(serviceItems.map((s, i) => {
                    const sum = (parseInt(s.summKZT) + parseInt(+s.commission)  + parseInt(+s.krsKayar)) * parseInt(s.quantity)
                    return +sum
                  })))} KZT</div>}
                </PortletFooter>
              </>}
            </Portlet>
            {edit && <Portlet>
              <PortletHeader title={'Оплаты в Google'}/>
              <PortletBody>
                {serviceItems.map((s, i) => {
                  return <div key={i} className={i + 1 !== serviceItems.length ? 'border-bottom mb-2' : ''}>
                    <GoogleItem s={s} i={i}/>
                  </div>
                })}
              </PortletBody>
            </Portlet>}
            {edit &&
              <>
                <Portlet>
                  <PortletBody>
                    <>Создано: <br/> Кем: {data.createdBy.name} <br/>Когда: {data.createDate} <br/></>
                    {!data.issuedWithoutDocs? 'Оплата по документам' : data.paid ? <>Отмечено оплаченым: <br/> Кем: {data.markedPaidBy.name} <br/>Когда: {data.markedPaidAt} </>:<b>Не оплачено</b>}
                  </PortletBody>
                  <PortletFooter>
                    <h5 className={'text-danger'}
                        onClick={removeOrder}
                        style={{cursor: 'pointer', textAlign: 'center'}}>
                      {data.isDeleted ? 'Восстановить услуги ' :'Удалить услуги '}<i className={"fa fa-trash"}/>
                    </h5>
                  </PortletFooter>
                </Portlet>
                {!data.isDeleted && <Portlet>
                  <PortletBody>
                    <Button onClick={copiedIvoice}>Дублировать инвойс</Button>
                  </PortletBody>
                </Portlet>}
              </>

            }
          </Col>
        </Row>
      </serviceContext.Provider>

    </>


  );
};

export default TouristServicesForm;
