import React from 'react';
import {PortletBody, PortletFooter} from "../../../../components/Portlet";
import FormRow from "../../../base/components/forms/formRow";
import {Col, Form, Row} from "react-bootstrap";
import LoadingButton from "../../../base/components/loadingButton";
import {Formik} from "formik";
import { validationSchema } from "./validationSchema";
import {useSelector} from "react-redux";

const BaseUserForm = ({user,callback, edit}) => {
  const { roles } = useSelector(state => state.auth.user);

  return (
    <Formik
      initialValues={
        user ? validationSchema.cast(user) : validationSchema.cast()
      }
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting }) => {
        console.log("form sent");
        setSubmitting(true);
        callback(values, () => {
          setSubmitting(false);
        });
      }}
    >
      {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => {
        console.log("err",errors);
        console.log('valuesr', values)
        return (
          <form noValidate={true} onSubmit={handleSubmit}>
            <PortletBody>
              <FormRow
                value={values.firstName}
                name={"firstName"}
                title={"Имя"}
                errors={errors}
                handleChange={handleChange}
                touched={touched}
              />
              <FormRow
                value={values.lastName}
                name={"lastName"}
                title={"Фамилия"}
                errors={errors}
                handleChange={handleChange}
                touched={touched}
              />

              <FormRow
                value={values.phone}
                name={"phone"}
                title={"Телефон"}
                placeholder={"+1 111 1111111"}
                errors={errors}
                handleChange={handleChange}
                touched={touched}
              />

              <FormRow
                value={values.telegramUsername}
                name={"telegramUsername"}
                title={"Имя в телеграмме"}
                errors={errors}
                handleChange={handleChange}
                touched={touched}
              />
              <FormRow
                value={values.railwaysLogin}
                name={"railwaysLogin"}
                title={"Имя ЖД"}
                errors={errors}
                handleChange={handleChange}
                touched={touched}
              />
              <FormRow
                value={values.ratehawkLogin}
                name={"ratehawkLogin"}
                title={"Имя Rate Hawk"}
                errors={errors}
                handleChange={handleChange}
                touched={touched}
              />
              <FormRow
                value={values.operatorCode}
                name={"operatorCode"}
                title={"Код операт. 1С"}
                errors={errors}
                handleChange={handleChange}
                touched={touched}
              />
              <FormRow
                value={values.email}
                name={"email"}
                title={"Почта"}
                disabled={edit}
                errors={errors}
                handleChange={(e)=> {
                  e.target.value = ("" + e.target.value).toLowerCase()
                  handleChange(e)
                }}
                touched={touched}
              />
              {!edit &&<Col md={{ span: 10, offset: 2 }}>
                <div
                  className="alert alert-secondary offset-sm-3"
                  role="alert"
                >
                  <div className="alert-text">
                    На указанную почту будет отправленно письмо с
                    инструкцией о сайте и как задать пароль
                  </div>
                </div>
              </Col>}
              {roles.indexOf("admin") === -1 ? <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Роль
                  </Form.Label>
                  <Col sm={10} className={'mt-2'}>
                    <Form.Check
                      value={values.role}
                      name={"role"}
                      type={"radio"}
                      label={rolesTranslater[values.role]}
                      id={values.role}
                      checked={true}
                      onChange={handleChange}
                      touched={touched}
                    />
                  </Col>
                </Form.Group> :
                <>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Роли
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        value={"account"}
                        name={"role"}
                        type={"radio"}
                        label={`Пользователь`}
                        id={`User`}
                        checked={values.role === "account"}
                        onChange={handleChange}
                        touched={touched}
                      />
                      <Form.Check
                        value={"accountant"}
                        name={"role"}
                        type={"radio"}
                        label={`Бухгалтер`}
                        id={`User`}
                        checked={values.role === "accountant"}
                        onChange={handleChange}
                        touched={touched}
                      />
                      <Form.Check
                        value={`employee`}
                        name={"role"}
                        type={"radio"}
                        label={`Сотрудник`}
                        id={`Employee`}
                        checked={values.role === "employee"}
                        onChange={handleChange}
                        touched={touched}
                      />
                      <Form.Check
                        value={`admin`}
                        name={"role"}
                        type={"radio"}
                        label={`Администратор`}
                        id={`Admin`}
                        checked={values.role === "admin"}
                        onChange={handleChange}
                        touched={touched}
                      />
                      <Form.Check
                        value={`tours_user`}
                        name={"role"}
                        type={"radio"}
                        label={`Создание туров`}
                        id={`tours_user`}
                        checked={values.role === "tours_user"}
                        onChange={handleChange}
                        touched={touched}
                      />
                    </Col>
                  </Form.Group>
                  <hr/>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Особое
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        value={"incomeReport"}
                        name={"specialPermissions"}
                        type={"checkbox"}
                        label={"Доход"}
                        id={"incomeReport"}
                        checked={values.specialPermissions.includes("incomeReport")}
                        onChange={handleChange}
                        touched={touched}
                      />
                      <Form.Check
                        value={"userEdit"}
                        name={"specialPermissions"}
                        type={"checkbox"}
                        label={"Редактирование пользователей"}
                        id={'userEdit'}
                        checked={values.specialPermissions.includes("userEdit")}
                        onChange={handleChange}
                        touched={touched}
                      />
                    </Col>
                  </Form.Group>
                </>
              }
              <hr/>
              {edit && !values.telegramChatId ? <>
                Чтобы подписаться на уведомления вам нужно написать нашему боту в Телеграмме. Обратитесь к
                администратору
              </> :
                edit && <Form.Group as={Row}>
              <Form.Label column sm="2">
                Получать уведомления
              </Form.Label>
              <Col sm={10} className={'mt-2'}>

                <Form.Check
                  value={"parse_check_errors"}
                  name={"sendTgNotifications"}
                  type={"checkbox"}
                  label={"Ошибки парса"}
                  id={values.sendTgNotifications}
                  checked={values.sendTgNotifications.includes("parse_check_errors")}
                  onChange={handleChange}
                  touched={touched}
                />
                <Form.Check
                  value={"trips_and_offers"}
                  name={"sendTgNotifications"}
                  type={"checkbox"}
                  label={"Предложения/Поездки"}
                  id={values.sendTgNotifications}
                  checked={values.sendTgNotifications.includes("trips_and_offers")}
                  onChange={handleChange}
                  touched={touched}
                />
                <Form.Check
                  value={"unpaid_ratehawk"}
                  name={"sendTgNotifications"}
                  type={"checkbox"}
                  label={"Неоплаченное RateHawk"}
                  id={values.sendTgNotifications}
                  checked={values.sendTgNotifications.includes("unpaid_ratehawk")}
                  onChange={handleChange}
                  touched={touched}
                />
                <Form.Check
                  value={"canceled_ratehawk"}
                  name={"sendTgNotifications"}
                  type={"checkbox"}
                  label={"Отмененные RateHawk"}
                  id={values.sendTgNotifications}
                  checked={values.sendTgNotifications.includes("canceled_ratehawk")}
                  onChange={handleChange}
                  touched={touched}
                />
                <Form.Check
                  value={"tech_1c"}
                  name={"sendTgNotifications"}
                  type={"checkbox"}
                  label={"Тех. 1С"}
                  id={values.sendTgNotifications}
                  checked={values.sendTgNotifications.includes("tech_1c")}
                  onChange={handleChange}
                  touched={touched}
                />
                <Form.Check
                  value={"tech_google_sheets"}
                  name={"sendTgNotifications"}
                  type={"checkbox"}
                  label={"Тех. Гугл Таблицы"}
                  id={values.sendTgNotifications}
                  checked={values.sendTgNotifications.includes("tech_google_sheets")}
                  onChange={handleChange}
                  touched={touched}
                />
                <Form.Check
                  value={"new_services"}
                  name={"sendTgNotifications"}
                  type={"checkbox"}
                  label={"Тур-услуги"}
                  id={values.sendTgNotifications}
                  checked={values.sendTgNotifications.includes("new_services")}
                  onChange={handleChange}
                  touched={touched}
                />
              </Col>
            </Form.Group>}
              <hr/>
              <Form.Group as={Row}>
                <Col sm={{span: 10, offset: 2}}>
                  <Form.Check
                    name={"active"}
                    type={"checkbox"}
                    id={`active`}
                    label={"Активирован"}
                    checked={values.active}
                    touched={touched}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>

            </PortletBody>
            <PortletFooter>
              <Row>
                <Col md={{ span: 10, offset: 2 }}>
                  <LoadingButton
                    title={"Сохранить"}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  />
                </Col>
              </Row>
            </PortletFooter>
          </form>
        );
      }}
    </Formik>
  );
};

export default BaseUserForm;

const rolesTranslater = {
  "account":"Пользователь",
  "accountant":"Бухгалтер",
  "employee":"Сотрудник",
  "admin":"Администратор",
  "tours_user":"Создание туров",
}
