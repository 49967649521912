import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {Helmet} from "react-helmet";
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import CustomDatePicker from "../../base/components/forms/customDatePicker";
import moment from "moment/moment";
import LoadingButton from "../../base/components/loadingButton";
import {Table} from "reactstrap";
import axios from "../../../helpers/axios";
import {Form} from "react-bootstrap";
import numberFormat from "../../../helpers/numberFormat";
import _, {values} from 'lodash'

const Consumption = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [internalTransfers, setInternalTransfers] = useState(false)
  const defaultVal= {
    start: moment().subtract(7, 'days').format('DD.MM.YYYY'),
    end: moment().format('DD.MM.YYYY'),
  }
  const formController = useForm({defaultValues:defaultVal})
  const {handleSubmit} = formController
  const submit = (data) =>{
    setLoading(true)

    axios.post('/api/fromOneC/consumption', data).then(({data})=>{
      const groupdSuppliers =_.groupBy( _.filter(data.oneC, {type:'Оплата поставщику'}),'counterparty.name' )
      let dataOneC = []

      Object.keys(groupdSuppliers).forEach(key=>{
        dataOneC.push({
          name: key,
          total:  _.sumBy(_.filter(groupdSuppliers[key], {toOurBank: false}), 'summ'),
          values: groupdSuppliers[key]
        })
      })

      const other = _.filter(data.oneC, (t)=>t.type !== 'Оплата поставщику')
      const grouppedOther = _.groupBy(other, 'type')

      dataOneC.push({
        name: 'Прочее',
        total: _.sumBy(_.filter(other, {toOurBank: false}), 'summ'),
        values: []
      })
      dataOneC.push(...Object.keys(grouppedOther).map(key=>{
        return {
          name: key,
          total:  _.sumBy(_.filter(grouppedOther[key]), 'summ'),
          values: grouppedOther[key]
        }
      }))
      const groupGoogle = _.groupBy(data.google, 'counterparty.name')
      const dataGoogle= []
      const googleTotal = ()=>{
        return Object.keys(_.groupBy(data.google, 'currency')).map((key, i) => {
          return <div key={i}>
            {numberFormat(_.sumBy(_.groupBy(data.google, 'currency')[key], 'summ'))} {key}
          </div>
        })
      }
      Object.keys(groupGoogle).forEach(key=>{
        dataGoogle.push({
            name: key,
            total:  _.sumBy(_.filter(groupGoogle[key]), 'summ'),
            values: groupGoogle[key]
          })
      })
      setData({google: dataGoogle, oneC: dataOneC, googleTotal})

    })
      .finally(()=>setLoading(false))
  }
console.log('data', data)

  const renderGroup = (table) =>{
    const renderRow = (row) => {
      return <tr>
        <td style={{paddingLeft:'0.5rem'}}>{row.list}</td>
        <td>{row.issueDate}</td>
        <td>{row.counterparty.name}</td>
        <td>{row.type}</td>
        <td className={'text-end'}>{numberFormat(row.summ)}</td>
        <td className={'text-center'}>{row.currency}</td>
        <td>{row.comment}</td>
      </tr>
    }
    return <>
      <tr style={{backgroundColor: "#def5df"}} >
        <th colSpan={4} style={{paddingLeft: '1rem'}}>{table.name}</th>
        <th className={'text-end'}>{numberFormat(table.total)}</th>
        <th>KZT</th>
        <td></td>
      </tr>
      {table.values.map((row, i) => {
        return <React.Fragment key={i}>
          {renderRow(row)}
        </React.Fragment>
      })}
    </>
  }


  return (
    <>
      <Helmet><title>Расходы</title></Helmet>
      <Portlet>
        <PortletHeader title={'Расходы'} toolbar={<PortletHeaderToolbar>
          <form onSubmit={handleSubmit(submit)} className={'d-flex gap-2'}>
            <CustomDatePicker formController={formController}
                              label={"Дата c"}
                              value={defaultVal.start}
                              name={`start`}
                              rules={{required: true}}/>
            <CustomDatePicker formController={formController}
                              label={"Дата по"}
                              value={defaultVal.end}
                              name={`end`}
                              rules={{required: true}}/>
            <LoadingButton loading={loading} title={'Получить'}/>
          </form>
          </PortletHeaderToolbar>}/>
        {data && <div className={'tableFixOnlyHead'} >
          <Table bordered size={'sm'}>
            <thead>
            <tr>
              <th>Откуда</th>
              <th>Дата</th>
              <th>Комму</th>
              <th>Тип</th>
              <th>Cумма</th>
              <th>Вал</th>
              <th>Комментарий</th>
            </tr>
            </thead>
            <tbody>
            <tr style={{backgroundColor: "#cfe2f3"}}>
              <th colSpan={4} style={{paddingLeft: '1rem'}}>1c</th>
              <th>{numberFormat(
                _.sumBy(data.oneC,'total')
              )}</th>
              <th>KZT</th>
              <td><Form.Label className={'d-flex align-items-center gap-2'}>
                <input
                  defaultChecked={internalTransfers}
                  onChange={(e) => {
                    setInternalTransfers(!internalTransfers)
                  }}
                  type={"checkbox"}
                />
                Показать внутренние транзакции
              </Form.Label></td>
            </tr>

            {data.oneC.map((table,index)=>{
              const ourTrans = ['Взнос наличными в банк','Перевод на другой счет организации']
              if(ourTrans.includes(table.name) && !internalTransfers){
                return
              } else {
                return <React.Fragment key={index} >
                  {renderGroup(table)}
                </React.Fragment>
              }
            })}
            <tr style={{backgroundColor: "#cfe2f3"}}>
              <th colSpan={4} style={{paddingLeft: '1rem'}}>Google касса</th>
              <th colSpan={3}>
                <div className={'d-flex gap-2'}>
                  <div>Итого:</div>
                  {data.googleTotal()}</div>
              </th>

            </tr>
            {data.google.map((table, i) => {
              return <React.Fragment key={i} >
                {renderGroup(table)}
              </React.Fragment>
            })}
            </tbody>
          </Table>
        </div>}
      </Portlet>
    </>
  );
};

export default Consumption;
