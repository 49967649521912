import React from 'react';
import {Row, Tab, Table, Tabs} from "react-bootstrap";
import moment from "moment";
import numberFormat from "../../../helpers/numberFormat";
import {showCount} from "../../../helpers/showCount";

const GoogleKayarRender = ({data}) => {
  console.log('google',data)

  return (
    <>
      <Row>
        <Tabs defaultActiveKey="correct">
          <Tab
            eventKey="correct"
            title={`Правильные (${data.correct.length})`}
          >
            <Table striped hover>
              {renderHeader()}
              {renderStandartBody(data.correct)}
            </Table>
          </Tab>
          <Tab
            eventKey="noGoogle"
            title={`Есть в Kayar но нет в Google (${showCount(data.googleMissing)})`}
          >
            <Table striped hover>
              {renderHeader()}
              {renderStandartBody(data.googleMissing)}
            </Table>
          </Tab>
          <Tab
            eventKey="noKayar"
            title={`Есть в Google но нет в Kayar (${showCount(data.kayarMissing)})`}
          >
            <Table striped hover>
              {renderHeader()}
              {renderStandartBody(data.kayarMissing)}
            </Table>
          </Tab>
          <Tab
            eventKey="diff"
            title={`Не соответствуют (${showCount(data.diff)})`}
          >
            <Table striped hover bordered>
              <thead>
              <tr>
                <td rowSpan={2}>Номер</td>
                <td className={'text-center'} colSpan={2}>Выписан</td>
                <td className={'text-center'} colSpan={2}>Покупатель</td>
                <td className={'text-center'} colSpan={2}>Итог</td>

                <td rowSpan={2}>Разница</td>
              </tr>
              <tr>
                {new Array(1,2,3).map((v,i)=>{
                  return <React.Fragment key={i}>
                    <td>Kayar</td>
                    <td>Google</td>
                  </React.Fragment>
                })}
              </tr>
              </thead>
              <tbody>
              {data.diff.map((u, index) => {
                const kayarIssueDate = moment(u.issueDate).format('DD.MM.YYYY')
                const googleIssueDate = moment(u.googleIssueDate).format('DD.MM.YYYY')
                return (
                  <tr key={index}>
                    <td>
                      {u.airline} {u.ticketNumber} {u.refund && '-В'}
                    </td>
                    {kayarIssueDate === googleIssueDate ?
                      <td colSpan={2} className={'text-center'}>{kayarIssueDate}</td>
                      : <>
                      <td>{kayarIssueDate}</td>
                      <td>{googleIssueDate}</td>
                    </>}

                    {renderValue(u.counterparty)}
                    {renderValue(u.total)}
                    <td className={'text-end'}>{typeof u.total === 'number'? 0:numberFormat(Math.abs(u.total.kayar - u.total.google))}</td>
                  </tr>
                );
              })}
              </tbody>
            </Table>
          </Tab>
          <Tab
            eventKey="unrecognized"
            title={`Неопределено Каяр (${showCount(data.unrecognized)})`}
          >
            <Table striped hover>
              {renderHeader()}
              {renderStandartBody(data.unrecognized)}
            </Table>
          </Tab>
          <Tab
            eventKey="unrecognizedGoogle"
            title={`Неопределено Google (${showCount(data.unrecognizedGoogle)})`}
          >
            <Table striped hover>
              {renderHeader()}
              {renderStandartBody(data.unrecognizedGoogle)}
            </Table>
          </Tab>
          <Tab
            eventKey="dupplicates"
            title={`Дублируются (${data.dupplicates.length})`}
          >
            <Table striped hover>
              <thead>
              <tr>
                <td>Номер</td>
                <td>Строчки</td>
              </tr>
              </thead>
              <tbody>
              {data.dupplicates.map((t,i)=>{
                return <tr key={i}>
                  <td>{t.ticket}</td>
                  <td>{t.rows.join()}</td>
                </tr>
              })}
              </tbody>
            </Table>
          </Tab>
        </Tabs>
      </Row>

    </>
  );
};

export default GoogleKayarRender;

const renderValue = (value) =>{
  if(typeof value !== 'object'){
    return <td className={'text-center'} colSpan={2}>{typeof value === 'number' ? numberFormat(value): value}</td>
  }else{
    return <>
      <td>{typeof value.kayar === 'number' ? numberFormat(value.kayar) : value.kayar}</td>
      <td>{typeof value.google === 'number' ? numberFormat(value.google) : value.google}</td>
    </>
  }
}

const renderHeader = () => {
  return <thead>
  <tr>
    <td>Номер</td>
    <td>Покупатель</td>
    <td>Откуда</td>
    <td>Выписан</td>
    <td>Итого</td>
  </tr>
  </thead>
}

const renderStandartBody = (array) =>{
  return <tbody>
  {array.map((u, index) => {
    return (
      <tr key={index}>
        <td>
          {u.airline} {u.ticketNumber} {u.refund && ' B'}
        </td>
        <td>{u.counterparty}</td>
        <td>{u.source}</td>
        <td>{moment(u.issueDate).format('DD.MM.YYYY')}</td>
        <td>{numberFormat(u.total)}</td>
      </tr>
    );
  })
  }
  <tr>
    <td colSpan={3}>
      <b>Итого</b>
    </td>
    <td>
      <b>
        {numberFormat(
          array
            .map(u => u.total)
            .reduce((a, b) => a + b, 0)
        )}
      </b>
    </td>
  </tr>
  </tbody>

}
