import React, {useContext, useState} from 'react';
import axios from "../../../helpers/axios";
import _ from "lodash";
import serviceContext from "./serviceContext";

const GoogleItem = ({s,i}) => {
  const {serviceItems,setServiceItems} = useContext(serviceContext)
  const [loading, setLoading] = useState(false)
  const [baseTitle, setBaseTitle] = useState(true)
  const checkGoogle = () =>{
    setLoading(true)
    axios.post('/api/touristServices/cashRevise/findZakup', {id:s.idInOneC})
      .then(({data})=>{
        if(data.result.length > 0){
          const newArr = _.filter(serviceItems, (s)=>(s.idInOneC !== s.idInOneC))
          const updatedItem = _.find(serviceItems,{idInOneC: s.idInOneC})
          updatedItem.tableRecord = data.result[0].tableRecord
          setServiceItems([...newArr, updatedItem])
        }else{
          setBaseTitle(false)
          setTimeout(()=>{
            setBaseTitle(true)
          }, 4000)
        }
      })
      .finally(()=>setLoading(false))
  }
  const renderString = () =>{
    if(baseTitle){
      return <>Нет инфо - <span className={'pointer link-info'}
                         onClick={checkGoogle}>Получить</span></>
    }else{
      return <>Мы ничего не нашли в Google таблице</>
    }
  }
  return (
    <>
      Услуга №{i + 1} - {s.idInOneC}<br/>
      {s.tableRecord && s.tableRecord.length >0 ? s.tableRecord.map((row, i)=> {
          return <div key={i}>
            <div className={'d-flex justify-content-between'}>
              <div>{row.tableName}</div>
              <div>Строка {row.row}</div>
            </div>
            {row.discription}
          </div>
        }) :
        loading ? 'Загружаю данные...' : renderString()}
    </>

  );
};

export default GoogleItem;
