import React, {useState} from 'react';
import SalesOneCounterparty from "../components/SalesOneCounterparty";
import axios from "../../../helpers/axios";
import {useNavigate} from "react-router";
import {SalesRegisterListRoute} from "../../routesConstants";
import {Helmet} from "react-helmet";
import SaleRegister from "../components/SaleRegister";

const CreateSalesRegister = () => {
  const [loading,setLoading] = useState(false)
  const navigate = useNavigate()
  const createRegister = (data) =>{
    console.log('create')
    setLoading(true)
    axios.put("/api/salesRegister/", data)
      .then(()=>{navigate(SalesRegisterListRoute)})
      .finally(()=>setLoading(false))
  }
  return (
    <div>
      <Helmet>
        <title>Реестр продаж</title>
      </Helmet>
      <SaleRegister cb={createRegister} saving={loading}/>
    </div>
  );
};

export default CreateSalesRegister;
