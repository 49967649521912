import {Route, Routes} from "react-router";
import React from "react";
import IncomeRooter from "./page/income/router"
import OSVRouter from "./page/osv/router";
import Consumption from "./page/consumption";



export default function() {
  return (
    <Routes>
      <Route path={"/income/*"} element={<IncomeRooter/>} />
      <Route path={"/osv/*"} element={<OSVRouter/>} />
      <Route path={"/consumption"} element={<Consumption/>}/>
    </Routes>
  );
}
