import React, {useState} from 'react';
import {Portlet, PortletBody, PortletFooter, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import {Form} from "react-bootstrap";
import LoadingButton from "../../base/components/loadingButton";
import axios from "../../../helpers/axios";
import SearchForm from "./SearchForm";
import _ from "lodash";
import {Link, useParams} from "react-router-dom";
import {SalesRegisterEditRoute} from "../../routesConstants";
import numberFormat from "../../../helpers/numberFormat";
import SaleTable from "./saleTable";
import DeletedBtn from "./deletedBtn";
import sayError from "../../../helpers/sayError";
import moment from "moment";

const SaleRegister = ({edit, dataBase, cb, saving}) => {
  const {id} = useParams();
  const [data, setData] = useState(dataBase?.tableData)
  const [description, setDescription] = useState(dataBase?.description || '')
  const [options, setOptions] = useState()

  const [loading,setLoading] = useState(false)
  const [showCol,setShowCol] = useState({name:false, krs:false})

  const getValues = (dataF)=>{
    setLoading(true)
    setOptions(dataF)
    axios.post('/api/salesRegister/getRegister', dataF)
      .then(({data})=>{
        document.title = `${data.header.counterparty} ${dataF.start.slice(0,5)}-${dataF.end}`
        data.tableData.forEach(inv=>{
            inv.total = inv.krsKayar ? inv.total+inv.krsKayar : inv.total
        })
        data.createTime = moment().format('DD.MM.YYYY HH:mm')
        console.log(data)
        data.tableData = _.sortBy(data.tableData, (r)=>moment(r.issueDate,'DD.MM.YYYY').unix())
        setData(data)

      })
      .finally(()=>setLoading(false))
  }
  const saveRegister = () =>{
    const send = !edit ?{
      ...options,
      tableData:data,
      description
    }:{
      tableData:data,
      description
    }
    cb && cb(send)
  }
  const markPaid = () =>{
    setLoading(true)
    axios.post('/api/salesRegister/markPaid/'+id)
      .then(()=>{
        window.location.reload()
      })
      .catch(sayError)
      .finally(()=>setLoading(false))}

  return (
    <div id={'main'}>
      <Portlet>
        <PortletHeader title={'Краткий реестр продаж'} toolbar={<PortletHeaderToolbar>
          {edit && data && <div className={'d-flex gap-2'}>
            <LoadingButton title={dataBase.isPaid ?'Оплачено':'Оплатить'}
                           variant={dataBase.isPaid ?'success':'primary'}
                           loading={loading}
                           onClick={markPaid}/>
            <DeletedBtn isDeleted={dataBase.isDeleted}/>
          </div>}
        </PortletHeaderToolbar>}/>
        <PortletBody>
          <SearchForm onSubmit={getValues} loading={loading} info={data} edit={edit}/>
          {data ?
            <>
              <div className={'d-flex gap-2'}>
                <Form.Label className={'d-flex align-items-center gap-2 mt-2'}>
                  <input
                    defaultChecked={showCol.name}
                    onChange={() => setShowCol(old => ({...old, name: !old.name}))}
                    type={"checkbox"}
                  />{" "}
                  Скрыть колонку "Документ/Операция"
                </Form.Label>
                <Form.Label className={'d-flex align-items-center gap-2 mt-2'}>
                  <input
                    defaultChecked={showCol.name}
                    onChange={() => setShowCol(old => ({...old, krs: !old.krs}))}
                    type={"checkbox"}
                  />{" "}
                  Показать колонки "КРС"
                </Form.Label>
              </div>
              {(data?.prevRegister && !edit) && <div>
                В базе найдет реестр для <Link to={SalesRegisterEditRoute + data.prevRegister.id}
                                                target={'_blank'}>{data.prevRegister.counterparty} на сумму: {numberFormat(data.prevRegister.summ)} KZT</Link> <br/>
                Период: {data.prevRegister.start} - {data.prevRegister.end}<br/>
                Оплачен: {data.prevRegister.isPaid ? <i className="fa fa-check-circle text-success"/> :
                <i className="fa fa-times-circle text-danger"/>}
              </div>}
              {/*{(edit && data.isPaid) && <div className={'text-end'}>*/}
              {/*  Оплачен: {data.markedPaidDate} {data.markedPaidBy}*/}
              {/*</div>}*/}
              {!!_.find(data.tableData, {transferred: true}) &&
                <h4 className={'text-danger text-center'}>
                  В реестре есть ПЕРЕСАЖЕННЫЕ билеты! Исправьте перед отправкой клиенту!
                  <br/>Пересаженные билеты окрашены красным
                </h4>}
              <div id="printarea" className={'register'}>
                <style type="text/css" media="print">
                  {" @page { size: landscape; } "}
                </style>
                <SaleTable editbleArray={data}
                           showCol={showCol}
                           setShowCol={setShowCol}
                           setEditableArray={setData}/>
                <h4>
                  Итого: <b>{numberFormat(_.sumBy(data.tableData, 'total'))} KZT</b>
                </h4>
              </div>
              <div className={'position-relative mt-3'}>
                <label className={'absolutLabel'}>Коментарий</label>
            <Form.Control
              as="textarea"
              rows="2"
              value={description}
              onChange={newVal => setDescription(newVal.target.value)}
            />
          </div>
            </> :
            <div>Задайте параметры и сформируйте таблицу</div>}
        </PortletBody>
        {data &&
          <PortletFooter>
            <LoadingButton
              title={"Сохранить таблицу"}
              type={'button'}
              onClick={saveRegister}
              disabled={saving || data.isDeleted === true}
              loading={saving}
            />
          </PortletFooter>}
      </Portlet>

    </div>
  );
};

export default SaleRegister;
