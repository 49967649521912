import React, {useEffect, useState} from 'react';
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import Button from "react-bootstrap/Button";
import axios from "../../../../helpers/axios";
import sayError from "../../../../helpers/sayError";
import {useController, useForm} from "react-hook-form";
import CustomDatePicker from "../../../base/components/forms/customDatePicker";
import SearchSelect from "../../../../components/searchSelect";
import FormHookSelect from "./formHookSelect";
import LoadingButton from "../../../base/components/loadingButton";

const type = {
  all:'Все',
  tickets:'Билеты',
  touristServices:'Тур-услуги'
}
const extendedQueryTemp = {
  start:moment().subtract(7, 'day').format('DD.MM.YYYY'),
  end:moment().format('DD.MM.YYYY'),
  buyer:'',
  type:'all'
}
const ReportHeadSearch = ({cb,query}) => {
  const [buyersList, setBuyersList] = useState()
  const [normalType, setNormalType] = useState(true)
  const formController = useForm()
  const {register,
    handleSubmit,
    control,
    formState:{errors}} = formController
  useEffect(() => {
    axios.post('/api/counterparty/searchInput', process.env.REACT_APP_COUNTERPARTIES_FROM_NEWBASE === "true" ? {fromNewBase: true} : {})
      .then(({data})=>{
        setBuyersList(data.data)
      })
  }, []);
  useEffect(() => {
    if(normalType && query.targetDate === undefined){
      cb({targetDate:new Date()})
    }
  }, [normalType]);
  const {
    field: { onChange: buyerOnChange, value: buyerValue }
  } = useController({
    name: "buyer",
    control,
    rules: { required: true },
    defaultValue: ""
  });
  const onSubmit = (data) =>{
    console.log('data', data)
  }

  return (
    <div className={'d-flex align-items-center gap-2 '}>
      {/*<Button  style={{minWidth:'max-content'}} onClick={()=>setNormalType(!normalType)}>{normalType ? 'Расширенный поиск' : 'Обычный поиск'}</Button>*/}
      {normalType ? <DatePicker
        selected={query.targetDate}
        autoComplete='off'
        className={"form-control"}
        dateFormat={'dd.MM.yyyy'}
        locale={'ru'}
        calendarStartDay={1}
        onChange={date => {
          console.log('date', moment(date).format('DD.MM.YYYY HH:mm'))
          cb(q => ({...q, targetDate: new Date(date)}))
        }}
      />: <form onSubmit={handleSubmit(onSubmit)} className={'d-flex align-items-center gap-2'}>
        <CustomDatePicker
          formController={formController}
          label={"Начало периода"}
          name={"start"}
          value={extendedQueryTemp.start}
          rules={{required: true}}
        />
        <CustomDatePicker
          formController={formController}
          label={"Конец периода"}
          name={"end"}
          value={extendedQueryTemp.end}
          rules={{required: true}}
        />
        <div style={{width: '200px'}}>
          <SearchSelect
            onChange={v => {
              buyerOnChange(v)
            }}
            defaultValue={extendedQueryTemp.buyer}
            label={'Покупатель'}
            array={[{value: '', label: 'Все'}, ...buyersList]}/>
        </div>
        <div style={{width: '150px'}}>
          <FormHookSelect
            defaultValue={extendedQueryTemp.type}
            register={register(`type`, {required: true})}
            // errors={errors}
            label={'Тип документа'}
          >
            {Object.keys(type).map((key, i) => {
              return <option value={key} key={i}>{type[key]}</option>

            })}
          </FormHookSelect>
        </div>

          <LoadingButton loading={false} title={'Загрузить'}/>
      </form>}
    </div>
  );
};

export default ReportHeadSearch;
