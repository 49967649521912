import React, {useEffect, useState} from 'react';
import moment from "moment";
import {useController} from "react-hook-form";
import DatePicker, {registerLocale} from "react-datepicker";
import ru from "date-fns/locale/ru"
registerLocale("ru", ru);


const CustomDatePicker = ({placeholder,name, formController, rules, value, className, time, label}) => {
  useEffect(() => {
    if(value && value !=='Invalid date'){
      time ? moment(value).format('DD.MM.YYYY HH:mm') : moment(value).format('DD.MM.YYYY')
      setMinDate(moment(value, 'DD.MM.YYYY HH:mm').toDate())
    }
  }, [value]);
  const {
    field: { onChange, value: currValue },
    fieldState
  } = useController({
    name,
    control: formController.control,
    rules,
    defaultValue:value
  });
  const [minDate, setMinDate] = useState((value && value !=='Invalid date')? moment(value, 'DD.MM.YYYY HH:mm').toDate(): null)


  let classNames = className ? className : "form-control";
  if (fieldState.error) {
    classNames += " is-invalid";
  }
  useEffect(() => {
    if(value === 'Invalid date'){
      onChange('')
    }
    // value && onChange(moment(value).toDate())
  }, []);
  return (
    <div style={{position: 'relative'}}>
      <label className={'absolutLabel'}>{label}</label>
      <DatePicker
        id={name}
        locale={'ru'}
        showTimeSelect={time}
        selected={minDate}
        timeIntervals={5}
        autoComplete='off'
        placeholderText={placeholder}
        className={classNames}
        timeFormat="HH:mm"
        dateFormat={time ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy'}
        calendarStartDay={1}
        onChange={date => {
          console.log('date', date)
          setMinDate(date)
          onChange(time ? moment(date).format('DD.MM.YYYY HH:mm') : moment(date).format('DD.MM.YYYY'))
        }}

      />
    </div>
  );
};

export default CustomDatePicker;
