import React from 'react';
import DateToFromPicker from "../../../touristServices/components/dateToFromPicker";
import SearchSelect from "../../../../components/searchSelect";
import {Form} from "react-bootstrap";
import _ from "lodash";
import ApiSelect from "../../../base/components/forms/apiSelect";

const ListQueryForm = ({query, setQuery}) => {
  return (
    <div className={'d-flex gap-2'}>
      <div style={{position: 'relative', width: 130}}>
        <label className={'absolutLabel'}>Имя</label>
        <Form.Control
          name={'pax'}
          value={query.pax}
          defaultValue={''}
          onChange={(e) => setQuery(q => ({...q, pax: e.target.value}))}
          className={'form-control'}
          type={'text'}
        />
      </div>
      <div style={{position: 'relative', width: 130}}>
        <label className={'absolutLabel'}>Бронирование</label>
        <Form.Control
          name={'bookingNumber'}
          defaultValue={''}
          value={query.bookingNumber}
          onChange={(e) => setQuery(q => ({...q, bookingNumber: e.target.value}))}
          className={'form-control'}
          type={'text'}
        />
      </div>
      <div style={{position: 'relative', width: 130}}>
        <label className={'absolutLabel'}>№ Билета</label>
        <Form.Control
          name={'ticketNumber'}
          defaultValue={""}
          value={query.ticketNumber}
          onChange={(e) => setQuery(q => ({...q, ticketNumber: e.target.value}))}
          className={'form-control'}
          type={'text'}
        />
      </div>
      <div style={{width:180}}>
        <ApiSelect
          value={query.buyer || ''}
          label={'Покупатель'}
          url={"/api/counterparty/searchInput"}
          cb={newVal => {
            setQuery(q => ({...q, buyer: newVal}));
          }}
        />
      </div>
      <div style={{width:220}}>
        <DateToFromPicker label={'Дата выписки с - по'}
                          defaultValue={query.issueDate ? {start:query.issueDate[0],
                            end:query.issueDate[1]} :{}}
                          setOptions={(v) => {
          setQuery(q => ({...q, issueDate: v}))
        }}/>
      </div>

      <div style={{width: 100}}>
        <SearchSelect
          onChange={(v) => {
            setQuery(q => ({...q, issuedWithoutDocs: v}))
          }}
          defaultValue={query.issuedWithoutDocs || ""}
          label={"Документы"}
          array={[{value: '', label: 'Все'}, {value: false, label: 'С док.'}, {
            value: true,
            label: 'Без'
          }]}/>
      </div>

    </div>
  );
};

export default ListQueryForm;
