import React, {useState} from 'react';
import CustomDatePicker from "../../base/components/forms/customDatePicker";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import moment from "moment/moment";
import {useForm} from "react-hook-form";
import {Portlet, PortletBody, PortletHeader} from "../../../components/Portlet";
import LoadingButton from "../../base/components/loadingButton";
import axios from "../../../helpers/axios";
import sayError from "../../../helpers/sayError";
import {Link} from "react-router-dom";
import {Table} from "react-bootstrap";
import {Helmet} from "react-helmet";

const PaxActivityInfo = () => {
  const [loading,setLoading] = useState(false)
  const [data,setData] = useState()
  const formController = useForm()
  const {
    register,
    formState:{errors},
    handleSubmit
  } = formController
  const defaultDate = moment().format('DD.MM.YYYY');
  const onSubmit = (data) =>{
    setLoading(true)
    axios.post('/api/paxActivity/', data)
      .then(({data})=>{
        console.log('data',data)
        setData(data.eventsByDay)
      })
      .finally(()=>setLoading(false))
  }
  return (
    <>
      <Helmet>
        <title>Активность туриста</title>
      </Helmet>
      <Portlet>
        <PortletHeader title={'Активность туриста'}/>
        <PortletBody>
          <form onSubmit={handleSubmit(onSubmit)} className={'d-flex align-items-end gap-2'}>
            <CustomDatePicker
              formController={formController}
              label={"Начало периода"}
              name={"startDate"}
              value={defaultDate}
              rules={{required: true}}
            />
            <CustomDatePicker
              formController={formController}
              label={"Конец периода"}
              name={"endDate"}
              value={defaultDate}
              rules={{required: true}}
            />
            <FormHookInput
              register={register(`lastName`, {
                required: true,
              })}
              style={{minWidth: '200px'}}
              label={'Фамилия'}
              errors={errors}
            />
            <FormHookInput
              register={register(`firstName`,{
                required: true,
              })}
              style={{minWidth: '200px'}}
              label={'Имя'}
              errors={errors}
            />
            <LoadingButton loading={loading} title={'Найти'}/>
          </form>
        </PortletBody>
      </Portlet>
      {data && <Portlet>
        <PortletBody>
          <Table bordered hover>
            <thead>
            <tr>
              <th>Дата</th>
              <th>Время</th>
              <th>Описание</th>
              <th className={'text-center'}>Ссылка</th>
            </tr>
            </thead>
            <tbody>
            {data.map((day,i)=>{
              return <React.Fragment key={i}>
                <tr>
                  <td rowSpan={day.events.length+1}>{day.day}</td>
                </tr>
                {day.events.map((e,i)=>{
                  return <tr key={i}>
                    <td>{e.time}</td>
                    <td>{e.discription}</td>
                    <td className={'text-center'}>
                      {e.links.map((l,index)=>{
                        return <div key={index}>
                          <Link to={e.baseUrl + l.id}
                                target={'_blank'} >
                            {l.label}
                          </Link>
                          <br/>
                        </div>
                      })}

                    </td>
                  </tr>
                })}
              </React.Fragment>
            })}
            </tbody>
          </Table>
          {data.length < 1 && 'Никакой активности в это время не найдено. Попробуйте другой период'}
        </PortletBody>
      </Portlet>}
    </>


  );
};

export default PaxActivityInfo;
