import {Route, Routes} from "react-router";
import IncomeList from "./list";
import OneCounterparty from "./oneCounterparty";
import OneByGrouped from "./oneByGrouped";
import DetailIncomePage from "./detailing";
import React from "react";

export default function() {
  return (
    <Routes>
      <Route path={"/"} element={<IncomeList/>} />

      <Route path={"/one"} element={<OneCounterparty/>} />
      <Route path={"/oneGrouped"} element={<OneByGrouped/>} />

      <Route path={"/detailing"} element={<DetailIncomePage/>}/>
    </Routes>
  );
}
