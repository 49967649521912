import React from "react";
import {
  Portlet,
  PortletBody,
  PortletFooter,
  PortletHeader
} from "../../../components/Portlet";
import { connect } from "react-redux";
import { CreateItem } from "../../../redux/actions/base";
import {
  AirportListRoute,
  CityListRoute, UserEditRoute,
  UserListRoute
} from "../../routesConstants";
import * as yup from "yup";
import FormRow from "../../base/components/forms/formRow";
import { Col, Form, Row } from "react-bootstrap";
import CitySelect from "../../base/components/forms/citySelect";
import LoadingButton from "../../base/components/loadingButton";
import { Formik } from "formik";
import {Helmet} from "react-helmet";
import {validationSchema} from "./components/validationSchema";
import BaseUserForm from "./components/baseUserForm";


const UserCreatePage = ({ CreateItem }) => {
  const callback = (values, cb) => {
    CreateItem("/api/users", values, cb, UserListRoute);
  };
  return (
    <>
      <Helmet>
        <title>Пользователь</title>
      </Helmet>
      <div className={"row"}>
        <div className="col-xl-6">
          <Portlet>
            <PortletHeader title={"Создание пользователя"} />
            <BaseUserForm callback={callback}/>
          </Portlet>
        </div>
      </div>
    </>
  );
};

export default connect(null, { CreateItem })(UserCreatePage);
