import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import axios from "../../../../helpers/axios";
import {getSearchParam} from "../../components/getSearchParam";
import {Portlet, PortletHeader, PortletHeaderToolbar} from "../../../../components/Portlet";
import SearchForm from "../../components/searchForm";
import {Table} from "reactstrap";
import _ from "lodash"
import numberFormat from "../../../../helpers/numberFormat";
import moment from "moment";
import {Helmet} from "react-helmet";

const OneByGrouped = () => {
  const { search } = useLocation();
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if(search){
      axios.post('/api/racoon/pdfParse/incomeReport/one',{
        ...getSearchParam(search),
        groupBySupplier: true
      }).then(({data})=>{
        data.table.forEach(sup=>{
          sup.netto= _.sumBy(sup.rows, 'netto')
          sup.krs1C=_.sumBy(sup.rows, 'krs1C')
          sup.krsKayar1C=_.sumBy(sup.rows, 'krsKayar1C')
          sup.krsSite= _.sumBy(sup.rows, 'krsSite')
        })
        setData(data.table)
      }).finally(()=>setLoading(false))
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>Подробный доход</title>
      </Helmet>
      <Portlet>
        <PortletHeader title={'Подбробно'} toolbar={<PortletHeaderToolbar>
          <SearchForm one
                      grouped
                      cb={(data, search)=>{
                        data.table.forEach(sup=>{
                          sup.netto= _.sumBy(sup.rows, 'netto')
                          sup.krs1C=_.sumBy(sup.rows, 'krs1C')
                          sup.krsKayar1C=_.sumBy(sup.rows, 'krsKayar1C')
                          sup.krsSite= _.sumBy(sup.rows, 'krsSite')
                        })
                        setData(data.table)
                        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname +
                          `?start=${search.start}&end=${search.end}&id=${search.counterparty}`;
                        window.history.pushState({path:newurl},'',newurl)
                      }}
                      url={'/api/racoon/pdfParse/incomeReport/one'}/>
        </PortletHeaderToolbar>}/>
        {data && <div className={'tableFixOnlyHead'}>
          <Table bordered>
            <thead>
            <tr>
              <th>Выписан</th>
              <th>Билет</th>
              <th>Пассажир</th>
              <th>Маршрут</th>
              <th>Нетто</th>
              <th>Крс 1с</th>
              <th>КрсКаяр 1с</th>
              <th>Крс Сайт</th>
              <th>Итого</th>
            </tr>
            </thead>
            <tbody>
            <tr style={{backgroundColor: "#d9ead3"}}>
              <td colSpan={4}>Итого</td>
              <td>{numberFormat(_.sumBy(data, 'netto'))}</td>
              <td>{numberFormat(_.sumBy(data, 'krs1C'))}</td>
              <td>{numberFormat(_.sumBy(data, 'krsKayar1C'))}</td>
              <td>{numberFormat(_.sumBy(data, 'krsSite'))}</td>
              <td>{numberFormat(_.sumBy(data, (s)=>(s.netto+s.krs1C+s.krsKayar1C+s.krsSite)))}</td>
            </tr>
            {data.map((sup, i) => {
              return <React.Fragment key={i}>
                <tr style={{backgroundColor: "#cfe2f3"}}>
                  <td colSpan={4}>{sup.counterparty}</td>
                  <td>{numberFormat(sup.netto)}</td>
                  <td>{numberFormat(sup.krs1C)}</td>
                  <td>{numberFormat(sup.krsKayar1C)}</td>
                  <td>{numberFormat(sup.krsSite)}</td>
                  <td>{numberFormat(sup.netto+sup.krs1C+sup.krsKayar1C+sup.krsSite)}</td>
                </tr>
                {_.sortBy(sup.rows, (r)=>(moment(r.issueDate,'DD.MM.YYYY').unix())).map((r,i)=>{
                  return <tr key={i}>
                    <td>{r.issueDate}</td>
                    <td><Link to={r.baseURL+r.id} target={'_blank'}>{r.ticket}</Link></td>
                    <td>{r.pax}</td>
                    <td>{r.route}</td>
                    <td>{numberFormat(r.netto)}</td>
                    <td>{numberFormat(r.krs1C)}</td>
                    <td>{numberFormat(r.krsKayar1C)}</td>
                    <td>{numberFormat(r.krsSite)}</td>
                    <td>{numberFormat(r.netto + r.krs1C + r.krsKayar1C + r.krsSite)}</td>
                  </tr>
                })}
              </React.Fragment>
            })}
            </tbody>
          </Table>
        </div>}

      </Portlet>
    </>

  );
};

export default OneByGrouped;
